import {
    Button,
    FormControlLabel,
    Switch,
    TextField,
} from '@material-ui/core'
import Autocomplete from '@mui/material/Autocomplete'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { convertArrayToText, convertFromErrorObject, convertLanguagesToShort, getTreeTaggerResult, isObjectsEqual, removeTagMark } from '../../../../utils'
import TLServiceComponent from '../../../../contexts/TLServiceComponent'
import config from '../../../../config'
import { allowlistText } from '../../../../actions'
import { useActions } from '../../../../utils/action-helper'

const AllowedList = ({ currentAllowedList, selectedText, editor, setBaseModalType, setDeleteAlertMsg, setErrorState, callEndPoint }) => {
    const {
        t,
        i18n: { language: i18nLanguage },
    } = useTranslation()
    const tlService = React.useContext(TLServiceComponent)
    const token = useSelector((state) => state.userData.token)
    const allowedListsData =
        useSelector((state) => state.userData.allowedLists) || []
    const userType = useSelector((state) => state.userData.user.user_type_id)
    const allowListText = useSelector((state) => state.analyseData.allowListText)
    const resultData = useSelector(
        (state) => state.analyseData.result.resultData
    )
    const popups = useSelector((state) => state.analyseData.popover.popups)
    const currentPopup = useSelector(
        (state) => state.analyseData.popover.currentPopup
    )
    const popUpData = !!popups[currentPopup] && popups[currentPopup].data
    const userId = useSelector((state) => state.userData.user.id)
    const { actionAllowlistText } = useActions({ actionAllowlistText: allowlistText })


    const [localeName, setLocaleName] = useState('')
    const [termName, setTermName] = useState('')
    const [idIs, setIdIs] = useState('')
    const [lemmaData, setLemmaData] = useState([])
    const [wordData, setWordData] = useState([])
    const [tagData, setTagData] = useState([])
    const [oldWord, setOldWord] = useState([])
    const [currentWord, setCurrentWord] = useState('')
    const [currentLemma, setCurrentLemma] = useState([])
    const [currentTag, setCurrentTag] = useState([])
    const [checkwordIs, setCheckwordIs] = useState(false)
    const [unknownLemmaWords, setUnknownLemmaWords] = useState('')
    const [showInput, setShowInput] = useState(false)
    const [buttonEnable, setButtonEnable] = useState(true)

    const allowCategoriesOptions = allowedListsData.map((el, index) => {
        const obj = {}
        obj.id = index + 1
        obj.categoryId = el.id
        obj.locale_name = el.locale_name
        obj.description = el.description[i18nLanguage]
        obj.settings = el.settings
        if (userType === 1) {
            obj.name = `(${el.id}) ${el.name[i18nLanguage]}`
        } else {
            obj.name = el.name[i18nLanguage]
        }
        return obj
    })
    const allowCategoriesOptionsArray = []
    allowCategoriesOptions.forEach((el) => {
        if (!allowCategoriesOptionsArray.includes(el.name)) {
            allowCategoriesOptionsArray.push(el.name)
        }
    })

    const selectAllowedList = (value) => {
        allowCategoriesOptions.forEach((el) => {
            if (el.name === String(value)) {
                setIdIs(el.categoryId)
                setLocaleName(el.locale_name)
            }
        })
    }
    useEffect(() => {
        // Finding the start and end position of the selected Text:
        if (selectedText !== undefined) {
            const oldFullContent = editor.getContent()
            const content = selectedText.getContent()
            const clearedContent = removeTagMark(content)
            const bm = selectedText.getBookmark()
            const bookmarkedContent = removeTagMark(editor.getBody().innerHTML.replaceAll('&nbsp;', ' '))
            const re = new RegExp(`<span data-mce-type="bookmark" id="${bm.id}_start" data-mce-style="overflow:hidden;line-height:0px" style="overflow: hidden; line-height: 0px;"></span>`)

            const startIndex = bookmarkedContent.search(re)
            const endIndex = startIndex + clearedContent.length

            editor.setContent(oldFullContent)
            const lemmaIs = []
            const wordIs = []
            const tagIs = []
            if (resultData?.dataTokenizer?.length) {
                for (const el of resultData.dataTokenizer) {
                    if (el.begin >= startIndex && el.end <= endIndex) {
                        if (el.type !== 'sgml') {
                            lemmaIs.push(el.lemma)
                            wordIs.push(el.word)
                            tagIs.push(el.tag)
                        }
                    }
                }
            }
            setLemmaData(lemmaIs)
            setWordData(wordIs)
            setTagData(tagIs)
            addingUnknownLemma(lemmaIs, wordIs)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        if (!!currentAllowedList) {
            // setCheckwordIs(currentAllowedList.check_words === 1)
            setCurrentWord(currentAllowedList.words)
            setOldWord(currentAllowedList.words)
            setCurrentLemma(currentAllowedList.lemma)
            setCurrentTag(currentAllowedList.tag)
        }
    }, [currentAllowedList])
    useEffect(() => {
        if (!!currentAllowedList) {
            allowCategoriesOptions?.length &&
                allowCategoriesOptions.forEach((el) => {
                    if (userType === 1) {
                        if (
                            el.name ===
                            `(${currentAllowedList.term_category_id}) ${currentAllowedList.term_category_name[i18nLanguage]}`
                        ) {
                            setIdIs(el.categoryId)
                            setTermName(el.name)
                            setLocaleName(el.locale_name)
                        }
                    } else {
                        if (
                            el.name === currentAllowedList.term_category_name[i18nLanguage]
                        ) {
                            setIdIs(el.categoryId)
                            setLocaleName(el.locale_name)
                            setTermName(el.name)
                        }
                    }
                })
        } else {
            const el = allowCategoriesOptions[0]
            setIdIs(el?.categoryId)
            setLocaleName(el?.locale_name)
            setTermName(el?.name)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentAllowedList])

    const shortLocale = convertLanguagesToShort(localeName)

    const addEditAllowedList = async () => {
        const element = allowCategoriesOptions.filter(
            (el) => el.categoryId === idIs
        )
        const settings = allowListText?.length
            ? popUpData?.term?.settings
            : element?.settings

        const catId = element[0]?.categoryId && element[0].categoryId
        if (!!catId) {
            let wordIs = currentWord?.length ? currentWord : wordData ? wordData : []
            let lemmaIs = currentLemma?.length ? currentLemma : lemmaData ? lemmaData : []
            let tagIs = currentTag?.length ? currentTag : tagData ? tagData : []
            let checkWords = 0
            const editedWord = typeof wordIs === 'string'
            if (lemmaIs?.length === 0 || editedWord) {
                try {
                    let tokenizeTerm = await getTreeTaggerResult(
                        tlService,
                        token,
                        localeName,
                        currentWord?.length
                            ? convertArrayToText(currentWord, shortLocale)
                            : allowListText?.length
                                ? convertArrayToText(allowListText, shortLocale)
                                : wordData?.length
                                    ? convertArrayToText(wordData, shortLocale)
                                    : ''
                    )
                    if (!tokenizeTerm?.error) {
                        wordIs = tokenizeTerm.word
                        lemmaIs = tokenizeTerm.lemma
                        tagIs = tokenizeTerm.tag
                    } else {
                        console.log('error ' + convertFromErrorObject(t, tokenizeTerm.error))
                    }
                } catch (e) {
                    setErrorState(convertFromErrorObject(t, e))
                }
            }

            if (lemmaIs?.length) {
                checkWords = lemmaIs.includes('<unknown>') ? 1 : checkwordIs ? 1 : 0
            }

            if (!!currentAllowedList) {
                try {
                    await tlService.editAllowedList(
                        token,
                        currentAllowedList.id,
                        wordIs,
                        lemmaIs,
                        tagIs,
                        catId,
                        checkWords,
                        userId,
                        '',
                        settings
                    )
                    setBaseModalType(undefined)
                    actionAllowlistText('')
                    callEndPoint()
                } catch (e) {
                    setErrorState(convertFromErrorObject(t, e))
                    setTimeout(() => {
                        setErrorState('')
                    }, config.timeOutDuration)
                }

            } else {
                try {
                    await tlService.addAllowedList(
                        token,
                        wordIs,
                        lemmaIs,
                        tagIs,
                        catId,
                        checkWords,
                        userId,
                        '',
                        settings
                    )
                    setBaseModalType(undefined)
                    actionAllowlistText('')
                } catch (e) {
                    setErrorState(convertFromErrorObject(t, e))
                    setTimeout(() => {
                        setErrorState('')
                    }, config.timeOutDuration)
                }
            }

        } else {
            setDeleteAlertMsg(t('Allowed-select'))
            setTimeout(() => {
                setDeleteAlertMsg('')
            }, config.timeOutDuration)
        }
    }
    const addingUnknownLemma = (lemma, word) => {
        let words = []
        lemma?.length > 0 && lemma.forEach((el, i) => {
            if (el === '<unknown>') {
                words.push(word[i])
            }
        })
        if (words?.length) {
            setUnknownLemmaWords(words.reduce((acc, v) => acc + `"${v}", `, '').replace(/, $/, ''))
        }
    }
    const callTreeTragger = async (lang, text) => {
        let tokenizeTerm = await getTreeTaggerResult(
            tlService,
            token,
            lang,
            text
        )
        if (!tokenizeTerm?.error) {
            setCurrentWord(tokenizeTerm.word)
            setCurrentLemma(tokenizeTerm.lemma)
            setCurrentTag(tokenizeTerm.tag)
            addingUnknownLemma(tokenizeTerm.lemma, tokenizeTerm.word)
            return tokenizeTerm
        } else {
            console.log('error ' + convertFromErrorObject(t, tokenizeTerm.error))
            return false
        }
    }

    const handleExpertDetails = async () => {
        if (!!currentAllowedList) {
            if (!isObjectsEqual(currentWord, oldWord) || !currentLemma?.length > 0) {
                const text = currentWord?.length ? convertArrayToText(currentWord, shortLocale) : allowListText?.length ? convertArrayToText(allowListText, shortLocale) : wordData?.length ? convertArrayToText(wordData, shortLocale) : ''
                callTreeTragger(localeName, text)
            }
        } else {
            if (currentWord?.length > 0 || !lemmaData?.length > 0) {
                const text = currentWord?.length ? convertArrayToText(currentWord, shortLocale) : allowListText?.length ? convertArrayToText(allowListText, shortLocale) : wordData?.length ? convertArrayToText(wordData, shortLocale) : ''
                callTreeTragger(localeName, text)
            }
        }
        setShowInput(true)
    }

    const handleSubmit = () => {
        setButtonEnable(false)
        addEditAllowedList()
        setButtonEnable(true)
    }

    return (
        <div style={{ width: "100%" }}>
            {allowCategoriesOptions?.length ? (
                <Autocomplete
                    freeSolo
                    id="combo-box-demo"
                    options={allowCategoriesOptionsArray}
                    sx={{ width: 300 }}
                    onChange={(event, newValue) => {
                        selectAllowedList(newValue)
                    }}
                    value={termName}
                    style={{
                        width: '100%',
                        backgroundColor: 'lightGray',
                        borderRadius: '10px',
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            InputProps={{
                                ...params.InputProps,
                                style: { fontWeight: 'bold', borderRadius: '10px' },
                            }}
                            label={t('Allowed list')}
                            variant="outlined"
                        />
                    )}
                />
            ) : (
                <h4 style={{ textAlign: 'center' }}>
                    {t('no-term-category-found')}
                </h4>
            )}
            <div>
                <TextField
                    size="small"
                    value={
                        currentWord?.length
                            ? convertArrayToText(currentWord, shortLocale)
                            : wordData?.length
                                ? convertArrayToText(wordData, shortLocale)
                                : allowListText?.length
                                    ? convertArrayToText(allowListText, shortLocale)
                                    : ''
                    }
                    name={'words'}
                    control={'text'}
                    placeholder={t('words')}
                    onChange={(e) => {
                        let wordIs = []
                        wordIs.push(e.target.value)
                        setCurrentWord(convertArrayToText(wordIs, shortLocale))
                    }}
                    fullWidth
                    className='mt-3'
                    variant="outlined"
                    label={t('term')}
                />
            </div>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'end',
                    marginTop: '30px',
                    marginBottom: '25px',
                    marginRight: showInput ? '0px' : '50px',
                }}
            >
                {!showInput ? (
                    <>
                        <div>
                            <Button variant="contained" color="primary" className={`ml-2`} onClick={handleExpertDetails}>{t('expert-details')}</Button>
                        </div>
                    </>
                ) : (
                    <>
                        <div>
                            <Button
                                variant="contained"
                                color="primary"
                                className={`ml-2`}
                                style={{ maxWidth: '250px' }}
                                onClick={() => {
                                    setShowInput(false)
                                }}
                            >
                                {t('hide-expert-details')}
                            </Button>
                        </div>
                    </>
                )}
            </div>
            {showInput && (
                <>
                    <div>
                        {(lemmaData?.includes('<unknown>') || currentLemma?.includes('<unknown>')) ?
                            <div>
                                <b>{t('Check-Words')} : </b> {t('use-lemma')}
                                <Switch
                                    disabled
                                    checked={true}
                                    onChange={() => setCheckwordIs(!checkwordIs)}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    className={'m-0'}
                                    label={'Find in text by lemmas'}
                                />
                                {t('only exact wording')}

                                <div style={{
                                    color: 'firebrick',
                                    font: 'status-bar'

                                }}>
                                    {t('lemma-unknown', {
                                        term: `"${currentWord?.length
                                            ? convertArrayToText(currentWord, shortLocale)
                                            : allowListText?.length
                                                ? convertArrayToText(allowListText, shortLocale)
                                                : wordData?.length
                                                    ? convertArrayToText(wordData, shortLocale)
                                                    : ''}."`,
                                        token: unknownLemmaWords
                                    })}
                                </div>
                            </div>
                            :
                            <div className='d-flex align-items-center'>
                                <p className='mt-2 mr-2'><b>{t('Check-Words')} : </b> {t('use-lemma')}</p>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={!!checkwordIs}
                                            onChange={() => {
                                                setCheckwordIs(prev => !prev)
                                            }}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                            className={'m-0'}
                                            label={'Find in text by lemmas'}
                                        />
                                    }
                                    label={t('only exact wording')}
                                />
                            </div>
                        }

                    </div>

                    <div className='d-flex flex-column mt-4 mb-4'>
                        <div className='mb-4'>
                            <b> {t('lemma')} : </b>
                            <TextField
                                disabled
                                size="small"
                                value={currentLemma?.length ? convertArrayToText(currentLemma, shortLocale) :
                                    lemmaData?.length
                                        ? convertArrayToText(lemmaData, shortLocale)
                                        : ''}
                                name={'lemma'}
                                placeholder={t('lemma')}
                                onChange={(e) => {
                                    setCurrentLemma(!!e.target.value ? e.target.value : [])
                                }}
                                fullWidth
                                variant="outlined"
                            />
                        </div>

                        <div>
                            <b> {t('tag')} : </b>
                            <TextField
                                disabled
                                size={'small'}
                                value={currentTag?.length ? convertArrayToText(currentTag, shortLocale) :
                                    tagData?.length
                                        ? convertArrayToText(tagData, shortLocale)
                                        : ''}
                                name={'tag'}
                                placeholder={t('tag')}
                                onChange={(e) => {
                                    setCurrentTag(e.target.value)
                                }}
                                fullWidth
                                variant="outlined"
                            />
                        </div>
                    </div>
                </>
            )}
            <div className={`d-flex justify-content-end align-items-center mt-2`}>
                <Button disabled={!buttonEnable} className={`mr-2`} onClick={handleSubmit} variant="contained" color="primary">{t('save')} </Button>
            </div>
        </div>
    )
}

export default AllowedList
