import React, { useEffect, useState } from 'react'
import style from './style.module.sass'
import { useTheme } from '@material-ui/core/styles'
import { Rnd } from 'react-rnd'
import { useTranslation } from 'react-i18next'
import AllowedList from './InnerComponents/AllowedList'
import { Collapse, IconButton, Typography } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { allowlistText } from '../../../actions'
import { useActions } from '../../../utils/action-helper'
import TextBin from './InnerComponents/TextBin'
import { Close } from '@material-ui/icons'

export default function BaseModal({ setBaseModalType, baseModalType, selectedText, editor, currentAllowedList, callEndPoint, headerText
}) {
    const { t } = useTranslation()
    const modalRef = React.createRef()
    const { theme: { bgColors, colors } } = useTheme()
    const [errorState, setErrorState] = useState('')
    const [deleteAlertMsg, setDeleteAlertMsg] = useState('')
    const [positionX, setPositionX] = useState(0)
    const [positionY, setPositionY] = useState(0)

    const { actionAllowlistText } = useActions({ actionAllowlistText: allowlistText })

    useEffect(() => {
        const updateCenterPosition = () => {
            const modalWidth = 1000
            const modalHeight = 400
            setPositionX((window.innerWidth - modalWidth) / 2 + window.scrollX)
            setPositionY((window.innerHeight - modalHeight) / 2 + window.scrollY)
        }
        updateCenterPosition()
        window.addEventListener('resize', updateCenterPosition)
        window.addEventListener('scroll', updateCenterPosition)
        return () => {
            window.removeEventListener('resize', updateCenterPosition)
            window.removeEventListener('scroll', updateCenterPosition)
        }
    }, [])

    const handleClosePopupWindow = () => {
        setBaseModalType(undefined)
        actionAllowlistText('')
    }

    let innerComponent = null
    switch (baseModalType) {
        case "AddAllowedList":
            innerComponent = (
                <AllowedList setBaseModalType={setBaseModalType} selectedText={selectedText} editor={editor} setErrorState={setErrorState} setDeleteAlertMsg={setDeleteAlertMsg} currentAllowedList={currentAllowedList} callEndPoint={callEndPoint} />
            )
            break
        case "AddTextTone":
            innerComponent = (
                <TextBin setBaseModalType={setBaseModalType} selectedText={selectedText} editor={editor} setErrorState={setErrorState} setDeleteAlertMsg={setDeleteAlertMsg} currentTerm={currentAllowedList} callEndPoint={callEndPoint} />
            )
            break
        default:
            break
    }
    return (
        <Rnd
            onDragStop={(e, d) => {
                setPositionX(d.x)
                setPositionY(d.y)
            }}
            position={{ x: positionX, y: positionY }}
            default={{ width: 640 }}
            style={{ cursor: 'default', zIndex: 1000 }}
            key={baseModalType}>
            <div ref={modalRef} style={{ backgroundColor: bgColors.bgPrimary0 }} className={`${style.mainCont}`}>
                <div
                    className={`${style.head} d-flex justify-content-between p-1 pl-3 pr-3`}>
                    <Typography className={`m-0 ${style.title}`} style={{ color: colors.colorBlue1000 }}>{t(headerText)}</Typography>
                    <IconButton className={`p-0 m-0`} onClick={handleClosePopupWindow} onMouseDown={e => e.stopPropagation()}>
                        <Close />
                    </IconButton>
                </div>
                <div className={`${style.fieldPart} d-flex align-items-start justify-content-between pl-3 pr-3 p-1 mt-2 mb-2`}>
                    {innerComponent}
                </div>
                <div className='mt-3 d-flex align-items-center justify-content-center'>
                    <Collapse in={!!errorState}>
                        <Alert className={`m-0 mb-3`} severity="error">
                            <p className={`m-0`}>{errorState}</p>
                        </Alert>
                    </Collapse>
                    <Collapse in={!!deleteAlertMsg}>
                        <Alert className={`m-0 mb-3`} severity="error">
                            <p className={`m-0`}>{deleteAlertMsg}</p>
                        </Alert>
                    </Collapse>
                </div>
            </div>
        </Rnd>
    )
}


