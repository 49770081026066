import React, { useEffect, useState } from "react";
import style from "./style.module.sass"
import folderUploadIcon from "../assets/Icon-metro-folder-download.svg"
import txtIcon from "../../../assets/txt-icon.png"
import docIcon from "../../../assets/doc-icon.png"
import docxIcon from "../../../assets/docx-icon.png"
import htmlIcon from "../../../assets/html-icon.png"

import { Add, Check, Equalizer, Replay, ViewDay, Remove, Delete } from "@material-ui/icons";
import autoFixHigh from "../../../assets/auto-fix-high.svg"
import { useTheme } from '@material-ui/core/styles';
import { Button, Collapse } from '@material-ui/core'
import ErrorModal from "../Overview/ErrorModal";
import ComponentWithPop from "../../common/ComponentWithPop";
import { NavLink } from "react-router-dom";
import WordExportModal from "./WordExportModal";
import AssignmentIcon from '@material-ui/icons/Assignment';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import WordSaveInArchive from "./WordSaveInArchive";
import ExportIssueDetails from "./ExportIssueDetails";
import { useTranslation } from "react-i18next";
import Dropzone from "react-dropzone";
import WordSaveNewVersion from "./WordSaveNewVersion";
import { checkAiText, isObjectEmpty } from "../../../utils";
import { useSelector } from "react-redux";
import {
    analyseSetBodyBorderShow,
    allowlistText,
    analyseSetAiMode
} from '../../../actions'
import ErrorPopup from "../ModalWindows/ErrorModal";
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import SaveIcon from '@material-ui/icons/Save';
import { useActions } from "../../../utils/action-helper";
import ComBotWhiteNoHorn from "../../../assets/combot-white-no-horn.svg";
import ComBotGreyNoHorn from "../../../assets/combot-grey-no-horn.svg";
import LightningModal from "../ModalWindows/LightningModal"
import BaseModal from "../../ModalWindows/BaseModal"

function CircularProgressWithLabel(props) {
    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress variant="determinate"
                size="2rem"
                {...props} />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography variant="caption" component="div" color="text.secondary" style={{ fontSize: "10px" }}>
                    {`${Math.round(props.value)}%`}
                </Typography>
            </Box>
        </Box>
    );
}

function HeadPart({
    handleShowBorders,
    editor,
    handleDownloadAsFile,
    handleRestartAll,
    mustReload,
    setMustReload,
    handleOnAnalyseClick,
    handleAnalyseFromAi,
    handleBatchProcessingUpload,
    selectedText,
    handleStopRVAnalyse,
    showUploadBar,
    uploadBarNow,
    checkKeys,
    showLoadText,
    setShowLoadText
}) {

    const { t } = useTranslation();

    const resultTemplate = useSelector(state => state.analyseData.result.resultTemplate);
    const bodyBorderShow = useSelector(state => state.analyseData.bodyBorderShow);
    // get user reducers
    const enabledSections = useSelector(state => state.userData.user?.enabled_frontend_sections || []);
    const customOptions = useSelector(state => state.userData.user?.custom_options || {});
    //const pathname = window.location.pathname;
    // const [openAddAllowedL, setOpenAddAllowedL] = useState(false);
    // const [openToBin, setOpenToBin] = useState(false);
    // const [wordExportOpen, setWordExportOpen] = useState(false);
    // const [wordSaveOpen, setWordSaveOpen] = useState(false);
    // const [wordSaveAsArchiveOpen, setWordSaveAsArchive] = useState(false);
    const [showDownloadMenu, setShowDownloadMenu] = useState(false);
    const [modalOpenType, setModalOpenType] = useState(undefined);
    const [modalState, setModalState] = useState(true);
    const [baseModalType, setBaseModalType] = useState(undefined)
    //const archives = useSelector(state => state.analyseData.archives);
    const saveArchive = useSelector(state => state.analyseData.saveArchive);
    const allowListText = useSelector(state => state.analyseData.allowListText)
    const analyseResult = useSelector(state => state.analyseData.result.resultData);
    const allowedListsData = useSelector(state => state.userData.allowedLists);
    const textToneData = useSelector(state => state.userData.textTone) || [];
    //const enableTextBin = useSelector(state => state.userData.user.user_options?.enable_textbin);
    //const userTypeId = useSelector(state => state.userData.user.user_type_id);
    const enabledTemplate = useSelector(state => state.userData.user.enabled_templates)
    const fileName = useSelector(state => state.analyseData.fileName)
    const aiMode = useSelector((state) => state.analyseData.aiMode)
    const text = useSelector((state) => state.analyseData.text)
    const enabledBenchmarks = useSelector(state => state.analyseData.enabledBenchmarks)
    const useBenchmark = useSelector(state => state.analyseData.useBenchmark)
    const aiMaxTextSize = useSelector((state) => state.userData.user.ai_settings?.max_text_size || {})

    const { theme: { bgColors, borderColors, colors } } = useTheme()
    //const success = useSelector(state => state.textEditor.success)
    //const success = true;
    const textIsSelected = { text: null, type: null };
    const onTextSelect = () => true;
    const [error, setError] = useState(null);

    const { aAnalyseSetAiMode, actionAnalyseSetBodyBorderShow, actionAllowlistText } = useActions({
      aAnalyseSetAiMode: analyseSetAiMode,
      actionAnalyseSetBodyBorderShow: analyseSetBodyBorderShow,
      actionAllowlistText: allowlistText
    });

    const aiButtonEnable = checkAiText(text, aiMaxTextSize, 'Assistant')
    const magicWandSettings = enabledBenchmarks[useBenchmark]?.settings?.magicWandPrompt?.values
    const magicWandEnable = !!(magicWandSettings?.enabled && magicWandSettings?.prompt)

    // If User Clicks on the Erlaubt(Allowed) btn from accordion, it opens up the modal for AllowList //
    useEffect(() => {
        if (allowListText?.length) {
            setBaseModalType(prev => prev === 'AddTextTone' ? 'AddTextTone' : 'AddAllowedList')
        }
    }, [allowListText])

    // To Show Border text if dataBodyRecognition exist:
    useEffect(() => {
        setTimeout(() => {
            if (analyseResult?.dataBodyRecognition && (analyseResult.dataBodyRecognition[0] > -1 || analyseResult.dataBodyRecognition[1] > -1)) {
                actionAnalyseSetBodyBorderShow(true);
                handleShowBorders(true)
            }
        }, [1000])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    let modalResult;

    switch (modalOpenType) {
        case "WordExportModal":
            modalResult = <WordExportModal setOpen={setModalOpenType} title={"Word export"} />
            break;
        case "WordSaveModal":
            modalResult = <WordExportModal setOpen={setModalOpenType} />
            break;
        case "WordSaveAsNewVersion":
            modalResult = <WordSaveNewVersion setOpen={setModalOpenType} modalState={modalState}
                setModalState={setModalState} />
            break;
        case "WordSaveInArchive":
            modalResult = <WordSaveInArchive setOpen={setModalOpenType} modalState={modalState}
                setModalState={setModalState} nextTabsAction={() => {
                    // setWordSaveAsArchive(false);
                    // setWordExportOpen(true)
                }} />
            break;
        case "exportIssueDetails":
            modalResult = <ExportIssueDetails setOpen={setModalOpenType} modalState={modalState}
                setModalState={setModalState} text={t("Export issue details")} />
            break;
        case "LightningModal":
            if (selectedText !== undefined) {
                modalResult = <LightningModal setOpen={setModalOpenType} open={true} selectedText={selectedText} editor={editor} handleAnalyseFromAi={handleAnalyseFromAi} />
            }
            break
        default:
            modalResult = null;
    }

    return (
        <div className={`${style.headPart} d-flex justify-content-between align-items-center pl-2 pr-2`}>
            <ErrorModal setState={onTextSelect} state={textIsSelected} />
            <ErrorPopup error={error} setError={setError} />
            {/* modalResult display a modal that are triggered by the user   */}
            {modalResult}
            {baseModalType && <BaseModal
                setBaseModalType={setBaseModalType} baseModalType={baseModalType} selectedText={selectedText}
                editor={editor} headerText={baseModalType === 'AddAllowedList' ? 'add-allowedList' : 'add-textbin' }/>}
            <span className={style.text}>
             {fileName}
             </span>
            <div style={{ color: colors.colorPrimary400 }} className={`d-flex position-relative`}>
            </div>
            <div className={`d-flex align-items-center`} style={{ height: "100%" }}>
                {(resultTemplate > 0) &&
                    <>
                        <hr style={{ borderColor: borderColors.borderPrimary200 }}
                            className={`m-0 ${style.hr} ${style.hidOnSmallScreen}  mr-2 ml-2`} />
                        {analyseResult?.dataBodyRecognition && (analyseResult.dataBodyRecognition[0] > -1 || analyseResult.dataBodyRecognition[1] > -1) &&
                            <>
                                <ComponentWithPop text={t("show body borders")}>
                                    <div
                                        onClick={() => handleShowBorders(!bodyBorderShow)}
                                        className={`d-flex ${style.hidOnSmallScreen}`}>
                                        <ViewDay style={{
                                            marginRight: "5px",
                                            cursor: "pointer",
                                            width: 25,
                                            fill: bodyBorderShow ? "black" : "lightgray"
                                        }} />
                                    </div>
                                </ComponentWithPop>
                                <hr style={{ borderColor: borderColors.borderPrimary200 }}
                                    className={`m-0 ${style.hr} ${style.hidOnSmallScreen}  mr-2`} />
                            </>
                        }
                        {enabledSections.indexOf("allowedlist") > -1 && allowedListsData?.length ?
                            <>
                                <ComponentWithPop text={t("add entry to my allowed list")}>
                                <div onClick={() => {
                                        if (selectedText !== undefined) {
                                            if (mustReload) {
                                                handleOnAnalyseClick()
                                            }
                                            onTextSelect('add')
                                            actionAllowlistText(selectedText.getContent()
                                            .replaceAll(/<br[^>]*>|<p[^/>]*>|<h\d[^/>]*>/g, " ")
                                            .replaceAll(/<\/?[^>]+>/g, "").trim())
                                            setBaseModalType('AddAllowedList')
                                        } else {
                                            setError({ text: t("select-some-text"), header: t("select-some-text") })
                                        }
                                }}
                                        className={`d-flex mr-2 ${style.withIcon} ${style.hidOnSmallScreen}`}>
                                        <Add className={style.icon} style={{ backgroundColor: bgColors.bgProgress100 }} />
                                        <Check style={{ fill: "#C1C1C1" }} />
                                    </div>
                                </ComponentWithPop>

                                <hr style={{ borderColor: borderColors.borderPrimary200 }}
                                    className={`m-0 ${style.hr} ${style.hidOnSmallScreen}  mr-2`} />
                            </> : null
                        }
                        {(textToneData?.length && enabledSections.indexOf("textbin") > -1) ? <>
                            <ComponentWithPop text={t('throw into the bin')}>
                                <div className={`d-flex mr-2 ${style.withIcon} ${style.hidOnSmallScreen}`}
                                onClick={() => {
                                        if (selectedText !== undefined) {
                                            if (mustReload) {
                                                handleOnAnalyseClick()
                                            }
                                            onTextSelect('bin')
                                            actionAllowlistText(selectedText.getContent()
                                            .replaceAll(/<br[^>]*>|<p[^/>]*>|<h\d[^/>]*>/g, " ")
                                            .replaceAll(/<\/?[^>]+>/g, "").trim())
                                            setBaseModalType('AddTextTone')
                                        } else {
                                            setError({ text: t("select-some-text"), header: t("select-some-text") })
                                        }
                                }}
                            >
                                    <Remove className={style.icon} style={{ backgroundColor: bgColors.bgProgress20 }} />
                                    <Delete style={{ fill: "#C1C1C1" }} />
                                </div>
                            </ComponentWithPop>
                            <hr style={{ borderColor: borderColors.borderPrimary200 }}
                                className={`m-0 ${style.hr} ${style.hidOnSmallScreen} mr-2`} />
                        </>
                            :
                            null}
                    {enabledSections.indexOf("magic_wand") > -1 && magicWandEnable ?
                        <>
                            <ComponentWithPop text={t("analyse_magic_wand-tooltip")}>
                                <div onClick={() => {
                                    if (selectedText !== undefined) {
                                        onTextSelect('ai')
                                        setModalOpenType('LightningModal')
                                        setModalState(true)
                                    } else {
                                        setError({ text: t("select-some-text"), header: t("select-some-text") })
                                    }
                                }}
                                    className={`d-flex mr-2 ${style.withIcon} ${style.hidOnSmallScreen}`}>
                                    <img src={autoFixHigh} alt="ai-magic" />
                                </div>
                            </ComponentWithPop>

                            <hr style={{ borderColor: borderColors.borderPrimary200 }}
                                className={`m-0 ${style.hr} ${style.hidOnSmallScreen}  mr-2`} />
                        </> : null
                    }
                        <ComponentWithPop text={t("download text")}>
                            <div onClick={()=>setShowDownloadMenu(s => !s)} className={`d-flex ${style.hidOnSmallScreen}`}>
                                <img src={folderUploadIcon} alt="" />
                            </div>
                        </ComponentWithPop>
                        <Collapse className={style.downloadMenu} in={showDownloadMenu}>
                            <ul onMouseLeave={() => setShowDownloadMenu(false)} className={style.downloadMenuUl}>
                                <li key="docx" title={t('docx-title')} onClick={() => {setShowDownloadMenu(false); handleDownloadAsFile("docx")}}><img src={docxIcon} alt="" /> {t("docx-file")}</li>
                                <li key="txt" title={t('txt-title')} onClick={() => {setShowDownloadMenu(false); handleDownloadAsFile("txt")}}><img src={txtIcon} alt="" /> {t("txt-file")}</li>
                                <li key="html" title={t('html-title')} onClick={() => {setShowDownloadMenu(false); handleDownloadAsFile("html")}}><img src={htmlIcon} alt="" /> {t("html-file")}</li>
                                <li key="doc" title={t('doc-title')} onClick={() => {setShowDownloadMenu(false); handleDownloadAsFile("doc")}}><img src={docIcon} alt="" /> {t("doc-file")}</li>
                            </ul>
                        </Collapse>
                        {/* <hr style={{borderColor: borderColors.borderPrimary200}}
                        className={`m-0 ${style.hr} ${style.hidOnSmallScreen} mr-2 ml-2`}/>
                    <ComponentWithPop text={t("save analysis")}>
                        <div onClick={() => setModalOpenType('WordSaveModal')} className={`d-flex ${style.hidOnSmallScreen}`}>
                            <Save style={{cursor: "pointer", width: 25, fill: colors.colorPrimary200}}/>
                        </div>
                    </ComponentWithPop> */}
                        {!!enabledTemplate["export_template_single"] && <>
                            <hr style={{ borderColor: borderColors.borderPrimary200 }}
                                className={`m-0 ${style.hr} ${style.hidOnSmallScreen} mr-2 ml-2`} />
                            <ComponentWithPop text={t("Export issue details")}>
                                <div onClick={() => {
                                    setModalOpenType('exportIssueDetails');
                                    setModalState(true);
                                }}
                                    className={`d-flex ${style.hidOnSmallScreen}`}>

                                    <AssignmentIcon style={{ cursor: "pointer", width: 25, fill: "green" }} />

                                </div>
                            </ComponentWithPop>
                            {/* <hr style={{borderColor: borderColors.borderPrimary200}}
                        className={`m-0 ${style.hr} ${style.hidOnSmallScreen} mr-2 ml-2`}/></> :
                        <div/> */}
                        </>
                        }
                        {enabledSections.indexOf("archive") > -1 &&
                            <>
                                {(saveArchive?.id && !isObjectEmpty(analyseResult)) ? <> <hr style={{ borderColor: borderColors.borderPrimary200 }}
                                    className={`m-0 ${style.hr} ${style.hidOnSmallScreen} mr-2 ml-2`} />
                                    <ComponentWithPop text={t("Save As New Version")}>
                                        <div onClick={e => {
                                            e.preventDefault();
                                            if (saveArchive?.id && !isObjectEmpty(analyseResult)) {
                                                setModalOpenType('WordSaveAsNewVersion');
                                                setModalState(true);
                                            }
                                        }}
                                            className={`d-flex ${style.hidOnSmallScreen}`}>
                                            {/* colors.colorPrimary200 */}
                                            <SaveIcon style={{ cursor: "pointer", width: 25, fill: "pink" }} />
                                        </div>
                                    </ComponentWithPop> </> : <></>}
                                <hr style={{ borderColor: borderColors.borderPrimary200 }}
                                    className={`m-0 ${style.hr} ${style.hidOnSmallScreen} mr-2 ml-2`} />
                                <ComponentWithPop text={t("Save As New Analysis")}>
                                    <div onClick={() => {
                                        setModalOpenType('WordSaveInArchive');
                                        setModalState(true);
                                    }}
                                        className={`d-flex ${style.hidOnSmallScreen}`}>
                                        <SaveIcon style={{ cursor: "pointer", width: 25, fill: (!isObjectEmpty(analyseResult)) ? "purple" : "pink" }} />
                                    </div>
                                </ComponentWithPop>
                            </>
                        }
                        {/* Old text import button commented part 1/2
                    <ComponentWithPop text={t("Word-upload")}>
                        <Dropzone onDrop={handleUploadFile}>
                            {({getRootProps, getInputProps}) => (
                                <div  className={`${style.hidOnSmallScreen} d-flex`}  {...getRootProps()}>
                                        <img src={wordIcon} alt=""/>
                                    <input accept=".txt,.docx,.pdf" id='uploadFile' {...getInputProps()} />
                                </div>
                            )}
                        </Dropzone>
                    </ComponentWithPop>*/}
                        <hr style={{ borderColor: borderColors.borderPrimary200 }}
                            className={`m-0 ${style.hr} ${style.hidOnSmallScreen} mr-2 ml-2`} />
                        {/* <ComponentWithPop text={t("batchProcessingButtonUpload")}>
                            <Dropzone onDrop={handleBatchProcessingUpload}>
                                {({ getRootProps, getInputProps }) => (
                                    <div className={`${style.hidOnSmallScreen} d-flex`}  {...getRootProps()}> */}
                                        {/* <img src={wordIcon} alt=""/> */}
{/*
                                        <InsertDriveFileIcon style={{ cursor: "pointer", width: 25, fill: colors.colorPrimary200 }} />

                                        <input accept=".txt,.docx,.pdf" id='batchProcessingUploadFile' {...getInputProps()} />
                                    </div>
                                )}
                            </Dropzone>
                        </ComponentWithPop> */}
                        <ComponentWithPop text={`${t('start-new-analysis')}`}>
                            <button
                                onClick={handleRestartAll}
                                style={{ backgroundColor: bgColors.bgPrimary300 }} className={`d-flex ${style.neu}`}>
                                <Add />
                            </button>
                        </ComponentWithPop>
                        <hr style={{ borderLeft: "1px solid #CCCCCC", height: "100%" }} className={`m-0 mr-2 ml-2`} />
                        {enabledSections.indexOf("dashboard") > -1 &&
                            <NavLink to={'/dashboard/single'}>
                                <ComponentWithPop text={`${t('go-to-the-Statistics')}`}>
                                    <button
                                    style={{ backgroundColor: bgColors.bgBlue1000, padding: 1 }} className={`d-flex ${style.neu}`}>
                                        <Equalizer style={{ width: "21px" }} />
                                    </button>
                                </ComponentWithPop>
                            </NavLink>
                        }
                        <hr style={{ borderLeft: "1px solid #CCCCCC", height: "100%" }} className={`m-0 mr-2 ml-2`} />
                        {enabledSections.indexOf("double-interface") > -1 && (
                            <div>
                            <ComponentWithPop text={aiButtonEnable ? `${t("switch to ai mode")}` : `${t("ai_text_too_long")}`}>
                                    <Button
                                    color="primary"
                                    disabled={!aiButtonEnable}
                                    variant="contained"
                                    className={`${style.actualBtn} ml-2 `}
                                    onClick={() => aAnalyseSetAiMode(!aiMode)}
                                >
                                    <img src={aiButtonEnable? ComBotWhiteNoHorn : ComBotGreyNoHorn} alt="ComLab AI Bot" width="27" style={{marginTop: -4}} />
                                    <p>{t("text bot button")}</p>
                                </Button>
                           </ComponentWithPop>
                        </div>
                        )}

                    </>
                }
                {(resultTemplate === 0) &&
                    <>
                        {/* Old text import button commented part 2/2
                           <Dropzone onDrop={handleUploadFile}>
                            {({getRootProps, getInputProps}) => (
                                <div  {...getRootProps()}>
                                    <label onClick={e => e.stopPropagation()} htmlFor="uploadFile"
                                           style={{backgroundColor: bgColors.bgBlue1000}}
                                           className={`pl-2 pr-2 p-1 ${style.actualBtn}`}>
                                        <img className={`mr-2`} src={wordUploadIcon} alt=""/>
                                        <p>{t("Word-upload")}</p>
                                    </label>
                                    <input accept=".txt,.docx,.pdf" id='uploadFile' {...getInputProps()} />
                                </div>
                            )}
                        </Dropzone>*/}
                        {enabledSections.indexOf("rv-processing") > -1 && customOptions["ruv_button"] !== undefined ?
                            <>
                                {(showLoadText) ?
                                    <div style={{ height: "5px", display: "flex", paddingLeft: "3px", marginTop: "-25px", paddingRight: "3px" }}>
                                        <h6 style={{ fontSize: "10px", marginTop: "10px" }}>{t("nect tot the FOR button")}</h6>
                                        <span style={{ fontSize: "8px", marginLeft: "20px" }}>
                                            <Box sx={{ display: 'flex' }}>
                                                <CircularProgress
                                                    size="2rem"
                                                />
                                            </Box>
                                        </span>
                                    </div>
                                    :
                                    !showUploadBar && <ComponentWithPop text={`${t('rv-processing')}`}>
                                        <Dropzone multiple={false} onDrop={(e) => {
                                            setShowLoadText(true);
                                            checkKeys(e);
                                        }
                                        }>
                                            {({ getRootProps, getInputProps }) => (
                                                <div  {...getRootProps()}>
                                                    <label onClick={e => e.stopPropagation()} htmlFor="batchRVUpload"
                                                        style={{ backgroundColor: bgColors.bgYellow1000 }}
                                                        className={`pl-2 pr-2 p-1 ml-2 ${style.actualBtn} ${style.autoWidth}`}>
                                                        <InsertDriveFileIcon style={{ cursor: "pointer", width: 25, fill: 'white', marginBottom: '2px' }} />
                                                        <p>{t("rv-process-button")}</p>
                                                    </label>
                                                    <input accept=".csv" id='batchRVUpload' {...getInputProps()} />
                                                </div>
                                            )}
                                        </Dropzone>
                                    </ComponentWithPop>}
                                {showUploadBar &&
                                    <div style={{ height: "5px", display: "flex", paddingLeft: "3px", marginTop: "-25px", paddingRight: "3px" }}>
                                        <h6 style={{ fontSize: "10px", marginTop: "10px" }}>{t("analysisInProgress")}</h6>
                                        <ComponentWithPop text={`${t('Stop the analyse pop')}`}>
                                            <Button
                                                onClick={handleStopRVAnalyse}
                                                style={{ cursor: "pointer" }}
                                                color="secondary"
                                                variant="contained"
                                                className={`${style.actualBtn} ml-2`}>
                                                {t("Stop the analyse button")}
                                            </Button>
                                        </ComponentWithPop>
                                        <span style={{ fontSize: "8px", marginLeft: "20px" }}> <CircularProgressWithLabel value={uploadBarNow} /> </span>
                                    </div>
                                }
                            </> : <></>
                        }
                        <ComponentWithPop text={`${t('Import-your-word')}`}>
                            {/* If user have no right for archive, batch upload is disabled: */}
                            <Dropzone onDrop={handleBatchProcessingUpload} multiple={!!enabledSections.includes('archive')}>
                                {({ getRootProps, getInputProps }) => (
                                    <div  {...getRootProps()}>
                                        <label onClick={e => e.stopPropagation()} htmlFor="batchProcessingUploadFile"
                                            style={{ backgroundColor: bgColors.bgBlue1000 }}
                                            className={`pl-2 pr-2 p-1 ml-2 ${style.actualBtn} ${style.autoWidth}`}>
                                            {/* <img className={`mr-2`} src={wordUploadIcon} alt=""/> */}
                                            <InsertDriveFileIcon style={{ cursor: "pointer", width: 25, fill: 'white', marginBottom: '2px' }} />
                                            <p>{t("batchProcessingButtonUpload")}</p>
                                        </label>
                                        <input accept=".txt,.docx,.pdf" id='batchProcessingUploadFile' {...getInputProps()} />
                                    </div>
                                )}
                            </Dropzone>
                        </ComponentWithPop>
                    </>
                }
                <ComponentWithPop text={`${t('Reload-the-analysis')}`}>
                    <Button
                        onClick={() => {
                            if (mustReload) {
                                setMustReload(false);
                                handleOnAnalyseClick();
                            }
                        }}
                        style={{ cursor: "pointer" }}
                        color="primary"
                        variant="contained"
                        disabled={!(mustReload)}
                        className={`${style.actualBtn} ml-2`}>
                        <Replay style={{ width: "21px" }} />
                        <p>{t("Update button")}</p>
                    </Button>
                </ComponentWithPop>
            </div>
        </div>
    )
}

export default HeadPart;
