import React, { useEffect, useState } from 'react'
import style from './style.module.sass'
import { useTheme } from '@material-ui/core/styles'
import closeIcon from '../../../../assets/close-circle.svg'
import { useTranslation } from 'react-i18next'
import {
  removeTagMark,
  textClear,
} from '../../../../utils'
import TLServiceComponent from '../../../../contexts/TLServiceComponent'
import ButtonWithPop from '../../Overview/ButtonWithPop'
import nextId from 'react-id-generator'
import { Add, Done } from '@material-ui/icons'
import { useSelector } from 'react-redux'
import ComBotBlue from '../../../../assets/combot-blue.svg'
import ComBotGray from '../../../../assets/combot-grey.svg'
import { Modal } from '@material-ui/core'
import { useActions } from '../../../../utils/action-helper'
import { analyseSetText } from '../../../../actions'

export default function LightningModal ({
  selectedText,
  setOpen,
  open,
  editor,
  handleAnalyseFromAi
}) {
  const { t } = useTranslation()
  const textToAnalyze = selectedText?.getContent()

  const token = useSelector(state => state.userData.token)
  const tlService = React.useContext(TLServiceComponent)
  const useBenchmark = useSelector(state => state.analyseData.useBenchmark)
  const benchmarks = useSelector(state => state.userData.benchmarks)

  const modalRef = React.createRef()
  const { theme: { bgColors, borderColors, colors } } = useTheme()

  const [loading, setLoading] = useState(false)
  const [suggestions, setSuggestions] = useState([])

  const {
    actionAnalyseSetText
  } = useActions({
    actionAnalyseSetText: analyseSetText,
  })

  const getSuggestions = async (text) => {
    setLoading(true)
    let customPrompt = ''
    if (useBenchmark > 0) {
      if (benchmarks[useBenchmark]?.settings?.magicWandPrompt?.values?.enabled) {
        customPrompt = benchmarks[useBenchmark]?.settings?.magicWandPrompt?.values?.prompt || ''
        customPrompt = customPrompt.trim()
        if (typeof customPrompt === 'string' && customPrompt.startsWith('[') && customPrompt.endsWith(']')) {
          customPrompt = JSON.parse(customPrompt)
        }
      }
    }

    try {
      const res = await tlService.runRedirect(token, {
        body: {
          user: textClear(text),
          system: customPrompt
        }
      }, 'Assistant')
      if (res?.data?.text) {
        const textResult = res.data.text.replace(/^\n/, '').replace(/\n$/, '')
        setSuggestions(prev => [...prev, textResult])
        setLoading(false)
      }
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    getSuggestions(textToAnalyze)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleReplaceTheSelectedText = (replacement) => {
    const arrText = replacement.split('\n\n')
    let res = ''
    const parentNode = selectedText?.getStart()

    if (parentNode?.nodeName !== 'P') {
      arrText.forEach((text, index) => {
        res += `<p>${text}</p>`
      })
    } else {
      if (arrText.length > 1) {
        arrText.forEach((text, index) => {
          if (index === 0) {
            res = `${text}</p>`
          } else if (index === arrText.length - 1) {
            res += `<p>${text}`
          } else {
            res += `<p>${text}</p>`
          }
        })
      } else {
        res = replacement
      }
    }
    if (selectedText) {

      const bm = selectedText.getBookmark()
      const rawContent = editor.getBody().innerHTML
      const bookmarkedContent = removeTagMark(rawContent).replaceAll('&nbsp;', ' ').replaceAll('<p><br></p>', '<p></p>')
      const bookSpanStart = `<span data-mce-type="bookmark" id="${bm.id}_start" data-mce-style="overflow:hidden;line-height:0px" style="overflow: hidden; line-height: 0px;"></span>`
      const bookSpanEnd = `<span data-mce-type="bookmark" id="${bm.id}_end" data-mce-style="overflow:hidden;line-height:0px" style="overflow: hidden; line-height: 0px;"></span>`
      const reStart = new RegExp(bookSpanStart)
      const reEnd = new RegExp(bookSpanEnd)
      const startIndex = bookmarkedContent.search(reStart)
      const endIndex = bookmarkedContent.search(reEnd)

      const newText = bookmarkedContent.substring(0, startIndex) + res + bookmarkedContent.substring(endIndex + bookSpanEnd.length)
      actionAnalyseSetText(newText)
      editor.selection.collapse()
      setTimeout(() => handleAnalyseFromAi(editor.getContent()), 200)
    }
  }

  const handleChange = (e, idX) => {
    const { value } = e.target
    const newSuggestions = suggestions.map((s, id) => {
      if (id === idX) {
        return value
      }
      return s
    })
    setSuggestions(newSuggestions)
  }

  if (!open) {
    return null
  }

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      style={{ cursor: 'default', zIndex: 1000 }}>
      <div ref={modalRef} style={{ backgroundColor: bgColors.bgPrimary0 }} className={`${style.mainCont} pb-3`}>
        <div
          style={{ backgroundColor: bgColors.bgPrimary300 }}
          className={`${style.head} d-flex justify-content-between p-1 pl-3 pr-3`}>
          <div className={style.imgdiv}>
            <div className={style.image}><img alt=""
                                              src={ComBotBlue}
                                              width={35}/>
            </div>
          </div>
          <p style={{ backgroundColor: bgColors.bgPrimary300 }}
             className={`m-0 ${style.title}`}>
            {t('analyse_magic_wand-mode')}
          </p>
          <button className={`border-0 bg-transparent pr-1 pl-2`} onClick={() => {
            setOpen(false)
          }} onMouseDown={e => e.stopPropagation()}>
            <img src={closeIcon} alt=""/>
          </button>
        </div>
        <div>
          <div
            className={`${style.fieldPart} d-flex align-items-start justify-content-between pl-3 pr-3 p-1 mt-2 mb-2`}>
            <div>
              <b
                dangerouslySetInnerHTML={{
                  __html: textToAnalyze
                }}/>
            </div>
          </div>
          {
            suggestions?.length > 0 &&
            <div className={`${style.rows} ml-3 mr-3 p-1 m-1`}
                 style={{ borderColor: borderColors.borderPrimary100 }}>
              {
                suggestions.map((suggestion, index) => {
                    return (
                      <div
                        className={`${style.row} p-1`} key={index}
                        style={{ borderColor: borderColors.borderPrimary100 }}>
                        <div className={`d-flex align-items-center justify-content-center`}>
                          <div className={style.fieldCont}>
                            <div>
                            <textarea type="text"
                                      rows={5}
                                      style={{
                                        borderColor: borderColors.borderPrimary100,
                                        lineHeight: '1.0em',
                                        width: '540px',
                                        minHeight: '80px',
                                        border: '2px solid lightgray',
                                        outline: 'none',
                                        padding: '10px',
                                        borderRadius: '15px',
                                        margin: '5px',
                                        background: `linear-gradient(0deg, ${bgColors.bgRadial} 3%, rgba(255,255,255,1) 7%)`,
                                      }}
                                      value={suggestion.replaceAll('<br>', '\n')}
                                      onMouseDown={e => e.stopPropagation()}
                                      onChange={(e) => handleChange(e, index)}
                            />
                            </div>
                          </div>
                          <div className={`d-flex align-items-center`}
                               style={{
                                 gridGap: '5px',
                                 marginLeft: '10px',
                               }}>
                            <ButtonWithPop
                              text={t('replace here')}
                              typeOfCount={''}
                              onClick={() => {
                                handleReplaceTheSelectedText(suggestion)
                                setOpen(false)
                              }}
                            >
                              <Done
                                key={nextId()}
                                style={{ fill: colors.colorProgress100, height: 24 }}/>
                            </ButtonWithPop>
                          </div>
                        </div>

                      </div>
                    )
                  }
                )
              }
            </div>
          }
          {
            !loading && <div className={`d-flex flex-column align-items-start`}
                             style={{
                               gridGap: '5px',
                               margin: '15px',
                             }}>
              <ButtonWithPop
                text={t('analyse_magic_wand_generate-more')}
                typeOfCount={''}
                onClick={() => {
                  getSuggestions(textToAnalyze)
                }}
              >
                <Add
                  key={nextId()}
                  style={{ fill: colors.colorPrimary800, height: 24 }}/>
              </ButtonWithPop>
              <p style={{
                color: colors.colorPrimary500,
              }}>{t('analyse_magic_wand_footer-tip')}</p>
            </div>
          }
        </div>
        {loading &&
          <div className={`mx-auto my-auto`}
               style={{ borderColor: borderColors.borderPrimary100 }}>
            <div className={`d-flex justify-content-center align-items-center`}>
              <div style={{ marginTop: '40px' }}><img
                alt={t('analyse_magic_wand_loading')} src={ComBotGray} width={30}
                className={style.android}/></div>
              <div
                style={{ marginLeft: '20px' }}>{t('analyse_magic_wand_loading')} ...
              </div>
            </div>
          </div>
        }
      </div>
    </Modal>
  )
}