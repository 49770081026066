import {
    Button,
    FormControlLabel,
    Switch,
    TextField,
} from '@material-ui/core'
import Autocomplete from '@mui/material/Autocomplete'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {useTheme} from '@material-ui/core/styles';
import { convertArrayToText, convertFromErrorObject, convertLanguagesToShort, getTreeTaggerResult, isObjectEmpty, isObjectsEqual, removeTagMark } from '../../../../utils'
import TLServiceComponent from '../../../../contexts/TLServiceComponent'
import config from '../../../../config'
import { allowlistText } from '../../../../actions'
import { Add } from '@material-ui/icons'
import { useActions } from '../../../../utils/action-helper'

const TextBin = ({ currentTerm, selectedText, editor, setBaseModalType, setDeleteAlertMsg, setErrorState, callEndPoint }) => {
    const {
        t,
        i18n: { language: i18nLanguage },
    } = useTranslation()
    const tlService = React.useContext(TLServiceComponent)
    const { theme: { bgColors, colors } } = useTheme()
    const token = useSelector((state) => state.userData.token)
    const textToneData = useSelector((state) => state.userData.textTone) || []
    const TermCatTexttone = useSelector(
        (state) => state.analyseData.termCatTexttone
    )
    const allowListText = useSelector((state) => state.analyseData.allowListText)
    const resultData = useSelector(
        (state) => state.analyseData.result.resultData
    )
    const popups = useSelector((state) => state.analyseData.popover.popups)
    const currentPopup = useSelector(
        (state) => state.analyseData.popover.currentPopup
    )
    const popUpData = !!popups[currentPopup] && popups[currentPopup].data
    const userId = useSelector((state) => state.userData.user.id)

    const { actionAllowlistText } = useActions({ actionAllowlistText: allowlistText })


    const [localeName, setLocaleName] = useState('')
    const [termName, setTermName] = useState('')
    const [idIs, setIdIs] = useState('')
    const [lemmaData, setLemmaData] = useState([])
    const [wordData, setWordData] = useState([])
    const [tagData, setTagData] = useState([])
    const [oldWord, setOldWord] = useState([])
    const [currentWord, setCurrentWord] = useState('')
    const [currentLemma, setCurrentLemma] = useState([])
    const [currentTag, setCurrentTag] = useState([])
    const [checkwordIs, setCheckwordIs] = useState(false)
    const [unknownLemmaWords, setUnknownLemmaWords] = useState('')
    const [showInput, setShowInput] = useState(false)
    const [descriptionIs, setDescriptionIs] = useState('')
    const [replacementBox, setReplacementBox] = useState(['first'])
    const [replacementText, setReplacementText] = useState('')
    const [replacementIs, setReplacementIs] = useState([])
    const [deletedReplacement, setDeletedReplacement] = useState([])
    const [newReplacement, setNewReplacement] = useState([])
    const [buttonEnable, setButtonEnable] = useState(true)

    const allowTextToneOptions = textToneData.map((el, index) => {
        const obj = {}
        obj.id = index + 1
        obj.locale_name = el.locale_name
        obj.categoryId = el.id
        obj.description = el.description[i18nLanguage]
        obj.settings = el.settings
        obj.name = `(${el.id}) ${el.name[i18nLanguage]}`
        return obj
    })
    const allowTextToneOptionsArray = []
    allowTextToneOptions.map((el) => {
        if (!allowTextToneOptionsArray.includes(el.name)) {
            allowTextToneOptionsArray.push(el.name)
        }
    })


    useEffect(() => {
        // Finding the start and end position of the selected Text:
        if (selectedText !== undefined) {
            const oldFullContent = editor.getContent()
            const content = selectedText.getContent()
            const clearedContent = removeTagMark(content)
            const bm = selectedText.getBookmark()
            const bookmarkedContent = removeTagMark(editor.getBody().innerHTML.replaceAll('&nbsp;', ' '))
            const re = new RegExp(`<span data-mce-type="bookmark" id="${bm.id}_start" data-mce-style="overflow:hidden;line-height:0px" style="overflow: hidden; line-height: 0px;"></span>`)

            const startIndex = bookmarkedContent.search(re)
            const endIndex = startIndex + clearedContent.length

            editor.setContent(oldFullContent)
            const lemmaIs = []
            const wordIs = []
            const tagIs = []
            if (resultData?.dataTokenizer?.length) {
                for (const el of resultData.dataTokenizer) {
                    if (el.begin >= startIndex && el.end <= endIndex) {
                        if (el.type !== 'sgml') {
                            lemmaIs.push(el.lemma)
                            wordIs.push(el.word)
                            tagIs.push(el.tag)
                        }
                    }
                }
            }
            setLemmaData(lemmaIs)
            setWordData(wordIs)
            setTagData(tagIs)
            addingUnknownLemma(lemmaIs, wordIs)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (!isObjectEmpty(currentTerm)) {
            let replacementBoxArray = []
            let replacementList = []
            let replacementArray = currentTerm.replacements

            setNewReplacement(replacementArray?.length ? replacementArray : [])
            setDescriptionIs(currentTerm?.description)
            setCurrentWord(currentTerm.words)
            setOldWord(currentTerm.words)
            setCurrentLemma(currentTerm.lemma)
            setCurrentTag(currentTerm.tag)

            allowTextToneOptions.forEach((el) => {
                if (
                    el.name ===
                    `(${currentTerm.term_category_id}) ${currentTerm.term_category_name[i18nLanguage]}`
                ) {
                    setIdIs(el.id)
                    setLocaleName(el.locale_name)
                    setTermName(el.name)
                }
            })
            replacementArray?.length &&
                replacementArray.forEach((a, i) => {
                    if (!replacementArray[i]?.includes(null)) {
                        replacementList.push(replacementArray[i])
                        replacementBoxArray.push(i)
                    }
                })
            if (replacementList[0] !== '') {
                setReplacementIs([...replacementIs, ...replacementList])
                setReplacementBox([...replacementBox, ...replacementBoxArray])
            }
            setCheckwordIs(currentTerm.check_words === 1)
        } else {
            for (let el of allowTextToneOptions) {
                if (el?.name?.includes(TermCatTexttone)) {
                    setTimeout(() => {
                        setTermName(el.name)
                        setIdIs(el.id)
                        setLocaleName(el.locale_name)
                    }, 100)
                    break
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentTerm])

    const shortLocale = convertLanguagesToShort(localeName)

    const addEditTextBin = async () => {
        if (!!idIs) {
            const element = allowTextToneOptions[parseInt(idIs - 1)]
            const settings = allowListText?.length
                ? popUpData?.term?.settings
                : element?.settings

            const catId = element?.categoryId && element.categoryId

            if (replacementText?.length) {
                replacementIs.push(replacementText)
            }

            let wordIs = currentWord?.length ? currentWord : wordData ? wordData : []
            let lemmaIs = currentLemma?.length ? currentLemma : lemmaData ? lemmaData : []
            let tagIs = currentTag?.length ? currentTag : tagData ? tagData : []
            let checkWords = 0
            const editedWord = typeof wordIs === 'string'
            if (lemmaIs?.length === 0 || editedWord) {
                try {
                    let tokenizeTerm = await getTreeTaggerResult(
                        tlService,
                        token,
                        localeName,
                        currentWord?.length
                            ? convertArrayToText(currentWord, shortLocale)
                            : allowListText?.length
                                ? convertArrayToText(allowListText, shortLocale)
                                : wordData?.length
                                    ? convertArrayToText(wordData, shortLocale)
                                    : ''
                    )
                    if (!tokenizeTerm?.error) {
                        wordIs = tokenizeTerm.word
                        lemmaIs = tokenizeTerm.lemma
                        tagIs = tokenizeTerm.tag
                    } else {
                        console.log('error ' + convertFromErrorObject(t, tokenizeTerm.error))
                    }
                } catch (e) {
                    setErrorState(convertFromErrorObject(t, e))
                }
            }

            if (lemmaIs?.length) {
                checkWords = lemmaIs.includes('<unknown>') ? 1 : checkwordIs ? 1 : 0
            }

            if (!!currentTerm) {
                try {
                    await tlService.editTextbin(
                        token,
                        currentTerm.id,
                        wordIs,
                        lemmaIs,
                        tagIs,
                        catId,
                        checkWords,
                        userId,
                        descriptionIs,
                        replacementIs,
                        settings
                    )
                    setBaseModalType(undefined)
                    actionAllowlistText('')
                    callEndPoint()
                } catch (e) {
                    setErrorState(convertFromErrorObject(t, e))
                    setTimeout(() => {
                        setErrorState('')
                    }, config.timeOutDuration)
                }

            } else {
                try {
                    await tlService.addTextTone(
                        token,
                        wordIs,
                        lemmaIs,
                        tagIs,
                        catId,
                        checkWords,
                        userId,
                        replacementIs,
                        descriptionIs,
                        settings
                    )
                    setBaseModalType(undefined)
                    actionAllowlistText('')
                } catch (e) {
                    setErrorState(convertFromErrorObject(t, e))
                    setTimeout(() => {
                        setErrorState('')
                    }, config.timeOutDuration)
                }
            }
        } else {
            setDeleteAlertMsg(t('Term-cat-select'))
            setTimeout(() => {
                setDeleteAlertMsg('')
            }, config.timeOutDuration)
        }
    }
    const addingUnknownLemma = (lemma, word) => {
        let words = []
        lemma?.length > 0 && lemma.forEach((el, i) => {
            if (el === '<unknown>') {
                words.push(word[i])
            }
        })
        if (words?.length) {
            setUnknownLemmaWords(words.reduce((acc, v) => acc + `"${v}", `, '').replace(/, $/, ''))
        }
    }
    const callTreeTragger = async (lang, text) => {
        let tokenizeTerm = await getTreeTaggerResult(
            tlService,
            token,
            lang,
            text
        )
        if (!tokenizeTerm?.error) {
            setCurrentWord(tokenizeTerm.word)
            setCurrentLemma(tokenizeTerm.lemma)
            setCurrentTag(tokenizeTerm.tag)
            addingUnknownLemma(tokenizeTerm.lemma, tokenizeTerm.word)
            return tokenizeTerm
        } else {
            console.log('error ' + convertFromErrorObject(t, tokenizeTerm.error))
            return false
        }
    }
    const removeReplacement = async (term) => {
        let indexIs = replacementIs.indexOf(term)
        let replacementIndex = newReplacement.indexOf(term)
        setDeletedReplacement([...deletedReplacement, term])
        // Remove from UI :
        newReplacement.splice(replacementIndex, 1)
        replacementIs.splice(indexIs, 1)
        replacementBox.splice(indexIs, 1)
        // setDeleteRecall(Math.floor(Math.random() * 10000));
        // callEndPoint()
    }
    const InputBox = () => {
        return (
            replacementBox?.length &&
            replacementBox.map((el, index) => {
                return (
                    <div key={index}>
                        {replacementBox.indexOf(el) < replacementBox?.length - 1 ? (
                            <div style={{ display: 'flex' }}>
                                <TextField
                                    size="small"
                                    disabled
                                    value={replacementIs[index]}
                                    style={{ width: '100%', marginBottom: '20px' }}
                                    onChange={(e) => setReplacementText(e.target.value)}
                                    label={t('replacement')}
                                    type="text"
                                    variant="outlined"
                                />
                                <button
                                    onClick={() => removeReplacement(replacementIs[index])}
                                    type="button"
                                    className="close"
                                    aria-label="Close"
                                    style={{ marginLeft: '10px', marginBottom: '20px' }}
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        ) : (
                            <div className='d-flex'>
                                <TextField
                                    size="small"
                                    value={replacementText}
                                    style={{ width: '100%' }}
                                    onChange={(e) => setReplacementText(e.target.value)}
                                    placeholder={t('replacement')}
                                    label={t('replacement')}
                                    InputLabelProps={{ shrink: true }}
                                    type="text"
                                    variant="outlined"
                                    aria-label={t('replacement')}
                                    />
                                    <Button variant="contained" color="primary" size="small"
                                        style={{
                                            minWidth: '10px',
                                            backgroundColor: bgColors.bgBlue1000,
                                            color: colors.colorPrimary0,
                                            marginLeft: '10px'
                                        }}
                                        onClick={() => addNewBox()} >
                                        <Add style={{fontSize: '20px'}} />
                                    </Button>
                                </div>
                        )}
                    </div>
                )
            })
        )
    }
    const addNewBox = () => {
        if (!!replacementText) {
            setNewReplacement([...newReplacement, replacementText])
            setReplacementIs([...replacementIs, replacementText])
            setReplacementBox([...replacementBox, Math.floor(Math.random() * 10000)])
            setReplacementText('')
        } else {
            setDeleteAlertMsg(t('fill-option'))
            setTimeout(() => {
                setDeleteAlertMsg('')
            }, config.timeOutDuration)
        }
    }
    const selectTexttoneList = (value) => {
        allowTextToneOptions.forEach((el) => {
            if (el.name === value) {
                setIdIs(el.id)
                setLocaleName(el.locale_name)
            }
        })
    }

    const handleExpertDetails = async () => {
        if (!!currentTerm) {
            if (!isObjectsEqual(currentWord, oldWord) || !currentLemma?.length > 0) {
                const text = currentWord?.length ? convertArrayToText(currentWord, shortLocale) : allowListText?.length ? convertArrayToText(allowListText, shortLocale) : wordData?.length ? convertArrayToText(wordData, shortLocale) : ''
                callTreeTragger(localeName, text)
            }
        } else {
            if (currentWord?.length > 0 || !lemmaData?.length > 0) {
                const text = currentWord?.length ? convertArrayToText(currentWord, shortLocale) : allowListText?.length ? convertArrayToText(allowListText, shortLocale) : wordData?.length ? convertArrayToText(wordData, shortLocale) : ''
                callTreeTragger(localeName, text)
            }
        }
        setShowInput(true)
    }
    const handleSubmit = () => {
        setButtonEnable(false)
        addEditTextBin()
        setButtonEnable(true)
    }
    return (
        <div style={{ width: "100%" }}>
            {
                <>
                    {allowTextToneOptions?.length ? (
                        <Autocomplete
                            freeSolo
                            id="combo-box-demo"
                            options={allowTextToneOptionsArray}
                            sx={{ width: 300 }}
                            onChange={(event, newValue) => {
                                selectTexttoneList(newValue)
                            }}
                            value={termName}
                            style={{
                                width: '100%',
                                backgroundColor: 'lightGray',
                                borderRadius: '10px',
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    InputProps={{
                                        ...params.InputProps,
                                        style: { fontWeight: 'bold', borderRadius: '10px' },
                                    }}
                                    label={t('Allowed list')}
                                    variant="outlined"
                                />
                            )}
                        />
                    ) : (
                        <h4 style={{ textAlign: 'center' }}>
                            {t('no-term-category-found')}
                        </h4>
                    )}
                    <br />
                    {
                        <TextField
                            size="small"
                            value={
                                currentWord?.length
                                    ? convertArrayToText(currentWord, shortLocale)
                                    : allowListText?.length
                                        ? convertArrayToText(allowListText, shortLocale)
                                        : wordData?.length
                                            ? convertArrayToText(wordData, shortLocale)
                                            : ''
                            }
                            onChange={(e) => {
                                let wordIs = []
                                wordIs.push(e.target.value)
                                setCurrentWord(convertArrayToText(wordIs, shortLocale))
                            }}
                            fullWidth
                            variant="outlined"
                            label={t('term')}
                            placeholder={t('All the best wishes')}
                        />
                    }
                </>
            }
            <div
                style={{ display: 'flex', justifyContent: 'end', marginTop: '30px', marginBottom: '25px', marginRight: showInput ? '0px' : '50px'}}>
                {!showInput ? (
                    <>
                        <div>
                            <Button variant="contained" color="primary" className={`ml-2`} onClick={handleExpertDetails}>{t('expert-details')}</Button>
                        </div>
                    </>
                ) : (
                    <>
                        <div>
                            <Button
                                variant="contained"
                                color="primary"
                                className={`ml-2`}
                                style={{ maxWidth: '250px' }}
                                onClick={() => {setShowInput(false)}}
                            >
                                {t('hide-expert-details')}
                            </Button>
                        </div>
                    </>
                )}
            </div>
            {showInput && (
                <>
                    <div>
                        {(lemmaData?.includes('<unknown>') || currentLemma?.includes('<unknown>')) ?
                            <div>
                                <b>{t('Check-Words')} : </b> {t('use-lemma')}
                                <Switch
                                    disabled
                                    checked={true}
                                    onChange={() => setCheckwordIs(!checkwordIs)}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    className={'m-0'}
                                    label={'Find in text by lemmas'}
                                />
                                {t('only exact wording')}

                                <div style={{
                                    color: 'firebrick',
                                    font: 'status-bar'

                                }}>
                                    {t('lemma-unknown', {
                                        term: `"${currentWord?.length
                                            ? convertArrayToText(currentWord, shortLocale)
                                            : allowListText?.length
                                                ? convertArrayToText(allowListText, shortLocale)
                                                : wordData?.length
                                                    ? convertArrayToText(wordData, shortLocale)
                                                    : ''}."`,
                                        token: unknownLemmaWords
                                    })}
                                </div>
                            </div>
                            :
                            <div className='d-flex align-items-center'>
                                <p className='mt-2 mr-2'><b>{t('Check-Words')} : </b> {t('use-lemma')}</p>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={!!checkwordIs}
                                            onChange={() => {
                                                setCheckwordIs(prev => !prev)
                                            }}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                            className={'m-0'}
                                            label={'Find in text by lemmas'}
                                        />
                                    }
                                    label={t('only exact wording')}
                                />
                            </div>
                        }

                    </div>
                    <div className='d-flex flex-column mt-4 mb-4'>
                        <div className='mb-4'>
                            <b> {t('lemma')} : </b>
                            <TextField
                                disabled
                                size="small"
                                value={currentLemma?.length ? convertArrayToText(currentLemma, shortLocale) :
                                    lemmaData?.length
                                        ? convertArrayToText(lemmaData, shortLocale)
                                        : ''}
                                name={'lemma'}
                                placeholder={t('lemma')}
                                onChange={(e) => {
                                    setCurrentLemma(!!e.target.value ? e.target.value : [])
                                }}
                                fullWidth
                                variant="outlined"
                            />
                        </div>

                        <div>
                            <b> {t('tag')} : </b>
                            <TextField
                                disabled
                                size={'small'}
                                value={currentTag?.length ? convertArrayToText(currentTag, shortLocale) :
                                    tagData?.length
                                        ? convertArrayToText(tagData, shortLocale)
                                        : ''}
                                name={'tag'}
                                placeholder={t('tag')}
                                onChange={(e) => {
                                    setCurrentTag(e.target.value)
                                }}
                                fullWidth
                                variant="outlined"
                            />
                        </div>
                    </div>
                </>
            )}
            <div className='mt-5'>
                <div className='d-flex align-items-center'>
                    <b className='mr-3'> {t('description')} : </b>
                    <TextField
                        type="text"
                        variant="outlined"
                        size="small"
                        control={'text'}
                        name={'description'}
                        placeholder={t('description')}
                        label={t('description')}
                        InputLabelProps={{ shrink: true }}
                        style={{
                            borderRadius: 50,
                            width: '87%',
                        }}
                        value={descriptionIs}
                        onChange={(e) => setDescriptionIs(e.target.value)}
                    />
                </div>
                <div className='mt-4'>
                    <div className='d-flex'>
                        <b className='mt-1' style={{marginRight:"0.75rem"}}> {t('replacement')} : </b>
                        <div style={{
                            width: '87%',
                        }} className=''>
                            {InputBox()}
                        </div>
                    </div>
                </div>
            </div>
            <div className={`d-flex justify-content-end align-items-center mt-4`}>
                <Button disabled={!buttonEnable}  onClick={handleSubmit} variant="contained" color="primary">{t('save')} </Button>
            </div>
        </div>
    )
}

export default TextBin
