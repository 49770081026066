import compose from './compose'
import fullLanguages from '../config/languages.json'
import LanguageDetect from 'languagedetect'
import siteLanguages from '../config/sitelanguages.json'
import { mammothAsync, readFileAsync, sleep } from './async-functions'
import { appendFilters, appendLimits, getResource } from './rest-functions'
import config from '../config'
import * as XLSX from 'xlsx'
import { asBlob } from './html-docx'
import { FiberManualRecordRounded } from '@material-ui/icons'
import { NavLink } from 'react-router-dom'
import { Menu } from '@material-ui/icons'
import { Button } from '@material-ui/core'

function log (...args) {
  // disable console.log in production!
  if (process.env.REACT_APP_CONSOLE_LOG !== 'false') {
    console.log(args)
  }
  return null
}

function getValue (arr, value, defaultValue = 0) {
  return (arr === undefined || arr[value] === undefined)
    ? defaultValue
    : arr[value]
}

function parseErrorObject (error) {
  try {
    error = JSON.parse(error.message)
  } catch (e) {
    error = { message: error.toString() }
  }

  return error
}

/**
 * convert from error object to translated message
 * @param translate the translation function from: const { t } = useTranslation();
 * @param error the object returned by backend
 * @param replacements this value is for backward compatability with an old format
 * @param parse
 * @returns {string|*} translated error
 */
function convertFromErrorObject (translate, error, replacements = {}, parse = true) {
  if (parse) {
    error = parseErrorObject(error)
  }

  // if message does not exist
  if (error['message'] === undefined)
    return ''

  const foundedValues = [...error['message'].matchAll(/{{([\w_]+)}}/g)]

  // if we have some values inside the message
  if (foundedValues.length > 0) {
    for (const val of foundedValues) {
      if (error[val[1]] !== undefined)
        replacements[val[1]] = error[val[1]]
      else
        replacements[val[1]] = ''
    }
  }

  return translate(error['message'].replaceAll(':', ''), {
    ...replacements,
    'ret_val': translate(replacements['ret_val'])
  })
}

function returnValuesFilter (ResultData, returnValues) {
  for (let el of Object.keys(ResultData)) {
    if (isObjectEmpty(returnValues[el]) || !returnValues[el]?.settings?.archive) {
      delete ResultData[el]
    }
  }
  return ResultData
}

function showStateAsText (state, translate) {
  switch (state) {
    case 0:
      return translate('Pending')
    case 1:
      return translate('Approved')
    case 2:
      return translate('Disapproved')
    default:
      return state
  }
}

function getScale (localPercent, localTarget, localScaleFrom, localScaleTo) {
  let scalePercent = localPercent
  let targetPercent = localTarget
  if (localScaleFrom !== 0 || localScaleTo !== 100) {
    const rangeScale = localScaleFrom < localScaleTo ? localScaleTo - localScaleFrom : localScaleFrom - localScaleTo
    if (localScaleFrom < localScaleTo) {
      scalePercent = localPercent > localScaleTo ? localScaleTo : localPercent < localScaleFrom ? localScaleFrom : localPercent
      targetPercent = localTarget > localScaleTo ? localScaleTo : localTarget < localScaleFrom ? localScaleFrom : localTarget
      scalePercent = (scalePercent - localScaleFrom) / (rangeScale / 100)
      targetPercent = (targetPercent - localScaleFrom) / (rangeScale / 100)
    } else {
      scalePercent = localPercent < localScaleTo ? localScaleTo : localPercent > localScaleFrom ? localScaleFrom : localPercent
      targetPercent = localTarget < localScaleTo ? localScaleTo : localTarget > localScaleFrom ? localScaleFrom : localTarget
      scalePercent = (rangeScale - (scalePercent - localScaleTo)) / (rangeScale / 100)
      targetPercent = (rangeScale - (targetPercent - localScaleTo)) / (rangeScale / 100)
    }
  }
  return [scalePercent, targetPercent]
}

function checkClixNotZero (settings) {
  const findForCLIXHere = (val) => {
    const retArray = []
    for (const listValue of Object.keys(val)) {
      if (val[listValue]?.clix && val[listValue].clix !== 0) {
        retArray.push(listValue)
      }
    }
    return retArray
  }

  const valuesClix = []
  let dataTermsClix = []
  let dataCorporateLanguageClix = []

  if (typeof settings === 'object') {
    for (const value of Object.keys(settings)) {
      // if starting with "list"
      if (value.startsWith('list')) {
        // dataTerms
        if (value === 'listCorporateLanguageTerms') {
          dataCorporateLanguageClix = findForCLIXHere(settings[value].values['_list'])
        }
        if (value === 'listTerms') {
          dataTermsClix = findForCLIXHere(settings[value].values['_list'])
        }
        continue
      }
      // for the other type of values
      if (settings[value]?.values?.clix && settings[value].values.clix !== 0) {
        valuesClix.push(value)
      }
    }
  }
  return { valuesClix, dataCorporateLanguageClix, dataTermsClix }
}

function deprecatedBenchmarkCheck (benchmarks, resultList) {
  if (!!benchmarks && !!resultList?.results?.benchmarkId) {
    for (let el of Object.keys(resultList?.results?.benchmarkId)) {
      if (!Object.keys(benchmarks).includes(el)) {
        return false
      }
    }
  }
  return true
}

function setFunctionsByReturnValueType (retVal, name) {
  if (retVal[name].value_type === 'array') {
    return 'arraysizesum'
  }
  return 'sum'
}

function chooseColor (value, targetYellowGreen, targetYellowRed) {
  if (targetYellowRed > targetYellowGreen)
    return (value < targetYellowGreen ? 'green' :
      value > targetYellowRed ? 'red' : 'yellow')
  return (value > targetYellowGreen ? 'green' :
    value < targetYellowRed ? 'red' : 'yellow')
}

function findFirstDiff (str1, str2, len = 20) {
  const finded = [...str1].findIndex((el, index) => el !== str2[index])
  return [str1.substr(finded - len, len * 2), str2.substr(finded - len, len * 2)]
}

function getListByColor (data, color) {
  for (let i of data) {
    if (i['category_type'] === color)
      return i
  }
  return null
}

function convertJSONToObject (jsonString, defaultValue = {}) {
  if (typeof jsonString === 'object')
    return jsonString
  try {
    return JSON.parse(jsonString)
  } catch (e) {
    return defaultValue
  }
}

function roundWithPrecision (num, precision = 0, string = false) {
  if (!isNaN(parseFloat(num))) {
    if (string) {
      return (+(num).toFixed(precision)).toLocaleString()
    } else {
      return +(num).toFixed(precision)
    }
  } else {
    return 0
  }
}

async function convertTextToTT (token, tlService, rawWords, locale_name) {

  let words = []
  let lemma = []
  let tag = []

  let res

  try {
    res = await tlService.getTreeTager(token, locale_name, rawWords)
  } catch (e) {
    console.log(e)
    return false
  }
  if (res && res['dataTokenizer']) {
    for (let data of res['dataTokenizer']) {
      words.push(data.word)
      lemma.push(data.lemma)
      tag.push(data.tag)
    }
    return { words, lemma, tag }
  }
  return false
}

function isObjectEmpty (obj) {
  if (typeof obj !== 'object')
    return obj === undefined

  // noinspection LoopStatementThatDoesntLoopJS
  for (const x in obj) {
    return false
  }
  return true
}

function getDataFromModals (props, index) {
  const { modalData: { modal } } = props
  return modal[index] ? modal[index].data : false
}

function getModalFromModals (props, index) {
  const { modalData: { modal } } = props
  return modal[index] ? modal[index] : false
}

function createDefaultMultilangObject (values = {}) {
  return Object.keys(siteLanguages).reduce((prev, current) => {
    return { ...prev, [current]: (values && values[current]) ? values[current] : '' }
  }, {})
}

function deepCopy (el) {
  return typeof el === 'object' ? JSON.parse(JSON.stringify(el)) : el
}

function isObjectsEqual (obj1, obj2) {
  return (typeof obj1 === 'object' && typeof obj2 === 'object') ? JSON.stringify(obj1) === JSON.stringify(obj2) : obj1 === obj2
}

function onlyUnique (value, index, self) {
  return self.indexOf(value) === index
}

function removeEmptyFromObject (object) {
  Object.entries(object).map(([key, value]) => {
    if (key.length === 0 || (typeof value === 'string' && value.length === 0)) {
      delete object[key]
    }
    return false
  })
  return object
}

function convertLanguagesToFull (language, hyphen = false) {
  if (language) {
    if (language.length === 5) return hyphen ? language.replace(/_/, '-') : language
    const langRegexp = new RegExp('^' + language + '\\_\\w{2}$')
    const langArray = Object.keys(fullLanguages)
    const langIndex = langArray.findIndex((element) => element.match(langRegexp))
    if (langIndex > -1) {
      return hyphen ? langArray[langIndex].replace(/_/, '-') : langArray[langIndex]
    }
  }
  return false
}

function convertLanguagesToShort (language) {
  if (language) {
    if (language.length === 2) return language
    if (fullLanguages[language] !== undefined) {
      return fullLanguages[language]['shortName']
    }
  }
  return false
}

/*
* examples:
* checkUserRight(user,[101,103],[2,2])
* company/over company rights should always be before group right (see examples below)
* checkUserRight(user,[201,101],[0,2])
* checkUserRight(user,[308,201,101,101],[0,0,2,1])
*  */
function checkUserRight (user, right = [], user_group = []) {
  if (user.user_type_id === 1)
    return true
  if (user?.user_rights?.length) {
    for (const tmpRight of user.user_rights) {
      const foundedIndex = right.indexOf(tmpRight.right_id)
      // forund user right in right array
      if (foundedIndex > -1) {
        // if user group id present and > 0
        if (user_group.length > foundedIndex && user_group[foundedIndex] > 0) {
          return user_group[foundedIndex] === user.user_rights[foundedIndex].user_group_id
        } else {
          // if not - approve
          return true
        }
      }
    }
  }
  return false
}

function checkGroupRight(user, targetUser, companyLevelRight, groupLevelRight) {
  const hasBaseEditRights = checkUserRight(user, [companyLevelRight])
  if (hasBaseEditRights) {
    return true
  }
  return user?.user_rights?.find(r => r.right_id === groupLevelRight && r.user_group_id === targetUser.user_group_id)
}

function stripHtml (html, stripList = []) {
  if (stripList?.length) {
    return stripList.reduce((acc, tag) => acc
        .replace(new RegExp(`<\\/*${tag}[^>]*>`, 'g'), '')
      , html)
  } else {
    const tmp = document.createElement('DIV')
    tmp.innerHTML = html
    return tmp.textContent || tmp.innerText || ''
  }
}

function clearHtml (html) {
  const doc = new DOMParser().parseFromString(html, 'text/html')
  let serialized = new XMLSerializer().serializeToString(doc)
  return serialized
    .replace(/^.*<body>(.+)<\/body>.*$/, '$1')
  //.replace(/(<img[^>]*>)/g,'<p>$1</p>')
}

function cumulativeOffset (element) {
  let top = 0, left = 0
  do {
    top += (element?.offsetTop || 0) - (element?.scrollTop || 0)
    left += (element?.offsetLeft || 0) - (element?.scrollLeft || 0)
    element = element.offsetParent
  } while (element)
  return { top, left }
}

function convertPlainToHTML (input_str) {
  let text_input //store input after beging trim()med
  let output_html = '' //store output
  let counter

  text_input = input_str.trim().replaceAll(/\r/g, '') //trim() input
  if (text_input.length > 0) {
    output_html += '<p>' //begin by creating paragraph
    for (counter = 0; counter < text_input.length; counter++) {
      switch (text_input[counter]) {
        case '\n':
          if (text_input[counter + 1] === '\n') {
            output_html += '<br /><br />'
            counter++
          } else output_html += '<br />'
          break

        case ' ':
          if (text_input[counter - 1] !== ' ' && text_input[counter - 1] !== '\t')
            output_html += ' '
          break

        case '\t':
          if (text_input[counter - 1] !== '\t')
            output_html += ' '
          break

        case '&':
          output_html += '&amp;'
          break

        case '"':
          output_html += '&quot;'
          break

        case '>':
          output_html += '&gt;'
          break

        case '<':
          output_html += '&lt;'
          break

        default:
          output_html += text_input[counter]

      }

    }
    output_html += '</p>' //finally close paragraph
  }
  return output_html // display output html
}

function removeBodyBorders (text) {
  return text.replace(/<span class="bodyborder">--------<\/span>/g, '')
}

function removeTagMark (text, bodyBorderShow = false) {
  text = bodyBorderShow ? text : removeBodyBorders(text)
  return text.replace(/<\/mark>/gm, '').replace(/<mark [^>]+>/gm, '')
}

function setLanguage (text) {
  const lngDetector = new LanguageDetect()
  lngDetector.setLanguageType('iso2')
  const detectedLanguage = lngDetector.detect(stripHtml(text))
  if (detectedLanguage && detectedLanguage[0] !== undefined && detectedLanguage[0][0] !== undefined) {
    const lang = convertLanguagesToFull(detectedLanguage[0][0])
    if (lang) {
      return lang
    }
  }
  return false
}

function convertUTCDateToLocalDate (date) {

  if (typeof date === 'string') {
    return new Date(date.replace(/:UTC$/, '.0000Z'))
  }
  let newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000)
  let offset = date.getTimezoneOffset() / 60
  let hours = date.getHours()
  newDate.setHours(hours - offset)
  return newDate
}

function printConvertedDate (date, lang) {
  return date.toLocaleDateString(convertLanguagesToFull(lang, true).toString(), {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    localeMatcher: 'best fit'
  }).replace(/\//g, '.').replace(/[a-z]/gi, '')
}

function prepareDate (date, lang) {
  return date.toLocaleDateString(convertLanguagesToFull(lang, true).toString(), {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    localeMatcher: 'best fit'
  }).replace(/\//g, '.').replace(/[a-z]/gi, '')
}

function dateToSearchString (date, hours = 0) {
  const ret = date.toISOString()
  if (hours === 0)
    return ret.replace(/T/, ' ').replace(/\.000Z/, '')
  if (hours > 0)
    return ret.replace(/T\d{2}:\d{2}:\d{2}.000Z/, ' 23:59:59')
  return ret.replace(/T\d{2}:\d{2}:\d{2}.000Z/, ' 00:00:00')
}

function convertArrayToText (text, lang = 'de') {
  if (typeof text === 'string') {
    return text
  }
  switch (lang) {
    case 'en':
      return convertArrayToTextEN(text)
    case 'de':
    default:
      return convertArrayToTextDE(text)
  }
}

function convertArrayToTextEN (text) {

  const oscillatingFirst = ['\'', '"', '‹', '‘', '»', '“']
  const oscillatingSecond = ['\'', '"', '›', '‘', '«', '”']
  let oscillatingCounter = { 0: 0, 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0 }

  let newtext = text.map(val => val + ' ')

  newtext[newtext.length - 1] = newtext[newtext.length - 1]?.trimEnd()

  for (let i in text) {

    // No space BEFORE following characters: .,;!?:)]}…%‰
    // AND no space before "['’´](s|re|ve|d|m|em|ll)|n['’´]t"
    //  || (text.substring(i).match(/^[.,;!?:)\]}…%‰]+$/)
    if (text[i].match(/^[.,;!?:)\]}…%‰]+$/)
      || text[i].match(/^(['’´](s|re|ve|d|m|em|ll)|n['’´]t)$/)) {
      if (i > 0) {
        newtext[i - 1] = newtext[i - 1].trimEnd()
      }
    } else if (text[i].match(/^[(\[{„¿¡‚†‡]+$/)) {
      //No space AFTER following characters: ([{„“[¿¡{‚„†‡
      if (i < newtext.length - 1) {
        newtext[i] = newtext[i].trimEnd()
      }
    }

    //oscillating
    const firstFind = oscillatingFirst.indexOf(text[i])
    const secondFind = oscillatingSecond.indexOf(text[i])

    if (firstFind !== -1 && oscillatingCounter[firstFind] === 0) {
      newtext[i] = newtext[i].trimEnd()
      oscillatingCounter[firstFind] = 1
    } else if (secondFind !== -1 && oscillatingCounter[secondFind] === 1) {
      if (i > 0) {
        newtext[i - 1] = newtext[i - 1].trimEnd()
      }
      oscillatingCounter[secondFind] = 0
    }
  }
  return newtext.join('')
}

function convertArrayToTextDE (text) {

  const oscillatingFirst = ['\'', '"', '‹', '‘', '»', '“']
  const oscillatingSecond = ['\'', '"', '›', '‘', '«', '”']
  let oscillatingCounter = { 0: 0, 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0 }

  let newtext = text.map(val => val + ' ')

  newtext[newtext.length - 1] = newtext[newtext.length - 1]?.trimEnd()

  for (let i in text) {

    // No space BEFORE following characters: .,;!?:)]}…%‰
    if (text[i].match(/^[.,;!?:)\]}…%‰]+$/)) {
      if (i > 0) {
        newtext[i - 1] = newtext[i - 1].trimEnd()
      }
    } else if (text[i].match(/^[(\[{„¿¡‚†‡]+$/)) {
      //No space AFTER following characters: ([{„“[¿¡{‚„†‡
      if (i < newtext.length - 1) {
        newtext[i] = newtext[i].trimEnd()
      }
    }

    //oscillating
    const firstFind = oscillatingFirst.indexOf(text[i])
    const secondFind = oscillatingSecond.indexOf(text[i])

    if (firstFind !== -1 && oscillatingCounter[firstFind] === 0) {
      newtext[i] = newtext[i].trimEnd()
      oscillatingCounter[firstFind] = 1
    } else if (secondFind !== -1 && oscillatingCounter[secondFind] === 1) {
      if (i > 0) {
        newtext[i - 1] = newtext[i - 1].trimEnd()
      }
      oscillatingCounter[secondFind] = 0
    }
  }
  return newtext.join('')
}

function checkTextSize (maxRequestSize, maxAnalizeTextSize, full, chars) {
  if (full > maxRequestSize) {
    return 'too big file size'
  }
  if (chars > maxAnalizeTextSize) {
    return 'text too long'
  }
  return ''
}

function findToNotHTML (text, start, limit = 0, direction = -1) {
  let i = start
  while (text[i] && text[i]['text'].match(/<.+>/)) {
    if (i === limit) return limit
    i += direction
  }
  return i
}

function getPositionNotHTML (text, start, limit = 1, direction = -1) {
  let i = start
  let counter = 0
  while (text[i]) {
    if (text[i]['text'].match(/<.+>/))
      continue
    counter++
    if (counter === limit) return i
    i += direction
  }
  return -1
}

// function exportTableToExcel(tableID, filename = ''){
//     var downloadLink;
//     var dataType = 'application/vnd.ms-excel';
//     var tableSelect = document.getElementById(tableID);
//     var tableHTML = tableSelect?.outerHTML?.replace(/ /g, '%20');

//     // Specify file name
//     filename = filename?filename+'.xls':'excel_data.xls';

//     // Create download link element
//     downloadLink = document.createElement("a");

//     document.body.appendChild(downloadLink);

//     if(navigator.msSaveOrOpenBlob){
//         var blob = new Blob(['\ufeff', tableHTML], {
//             type: dataType
//         });
//         navigator.msSaveOrOpenBlob( blob, filename);
//     }else{
//         // Create a link to the file
//         downloadLink.href = 'data:' + dataType + ', ' + tableHTML;

//         // Setting the file name
//         downloadLink.download = filename;

//         //triggering the function
//         downloadLink.click();
//     }
// }

function md5Generator (inputString) {
  var hc = '0123456789abcdef'

  function rh (n) {
    var j, s = ''
    for (j = 0; j <= 3; j++) s += hc.charAt((n >> (j * 8 + 4)) & 0x0F) + hc.charAt((n >> (j * 8)) & 0x0F)
    return s
  }

  function ad (x, y) {
    var l = (x & 0xFFFF) + (y & 0xFFFF)
    var m = (x >> 16) + (y >> 16) + (l >> 16)
    return (m << 16) | (l & 0xFFFF)
  }

  function rl (n, c) {
    return (n << c) | (n >>> (32 - c))
  }

  function cm (q, a, b, x, s, t) {
    return ad(rl(ad(ad(a, q), ad(x, t)), s), b)
  }

  function ff (a, b, c, d, x, s, t) {
    return cm((b & c) | ((~b) & d), a, b, x, s, t)
  }

  function gg (a, b, c, d, x, s, t) {
    return cm((b & d) | (c & (~d)), a, b, x, s, t)
  }

  function hh (a, b, c, d, x, s, t) {
    return cm(b ^ c ^ d, a, b, x, s, t)
  }

  function ii (a, b, c, d, x, s, t) {
    return cm(c ^ (b | (~d)), a, b, x, s, t)
  }

  function sb (x) {
    var i
    var nblk = ((x.length + 8) >> 6) + 1
    var blks = new Array(nblk * 16)
    for (i = 0; i < nblk * 16; i++) blks[i] = 0
    for (i = 0; i < x.length; i++) blks[i >> 2] |= x.charCodeAt(i) << ((i % 4) * 8)
    blks[i >> 2] |= 0x80 << ((i % 4) * 8)
    blks[nblk * 16 - 2] = x.length * 8
    return blks
  }

  var i, x = sb(inputString), a = 1732584193, b = -271733879, c = -1732584194, d = 271733878, olda, oldb, oldc, oldd
  for (i = 0; i < x.length; i += 16) {
    olda = a
    oldb = b
    oldc = c
    oldd = d
    a = ff(a, b, c, d, x[i], 7, -680876936)
    d = ff(d, a, b, c, x[i + 1], 12, -389564586)
    c = ff(c, d, a, b, x[i + 2], 17, 606105819)
    b = ff(b, c, d, a, x[i + 3], 22, -1044525330)
    a = ff(a, b, c, d, x[i + 4], 7, -176418897)
    d = ff(d, a, b, c, x[i + 5], 12, 1200080426)
    c = ff(c, d, a, b, x[i + 6], 17, -1473231341)
    b = ff(b, c, d, a, x[i + 7], 22, -45705983)
    a = ff(a, b, c, d, x[i + 8], 7, 1770035416)
    d = ff(d, a, b, c, x[i + 9], 12, -1958414417)
    c = ff(c, d, a, b, x[i + 10], 17, -42063)
    b = ff(b, c, d, a, x[i + 11], 22, -1990404162)
    a = ff(a, b, c, d, x[i + 12], 7, 1804603682)
    d = ff(d, a, b, c, x[i + 13], 12, -40341101)
    c = ff(c, d, a, b, x[i + 14], 17, -1502002290)
    b = ff(b, c, d, a, x[i + 15], 22, 1236535329)
    a = gg(a, b, c, d, x[i + 1], 5, -165796510)
    d = gg(d, a, b, c, x[i + 6], 9, -1069501632)
    c = gg(c, d, a, b, x[i + 11], 14, 643717713)
    b = gg(b, c, d, a, x[i], 20, -373897302)
    a = gg(a, b, c, d, x[i + 5], 5, -701558691)
    d = gg(d, a, b, c, x[i + 10], 9, 38016083)
    c = gg(c, d, a, b, x[i + 15], 14, -660478335)
    b = gg(b, c, d, a, x[i + 4], 20, -405537848)
    a = gg(a, b, c, d, x[i + 9], 5, 568446438)
    d = gg(d, a, b, c, x[i + 14], 9, -1019803690)
    c = gg(c, d, a, b, x[i + 3], 14, -187363961)
    b = gg(b, c, d, a, x[i + 8], 20, 1163531501)
    a = gg(a, b, c, d, x[i + 13], 5, -1444681467)
    d = gg(d, a, b, c, x[i + 2], 9, -51403784)
    c = gg(c, d, a, b, x[i + 7], 14, 1735328473)
    b = gg(b, c, d, a, x[i + 12], 20, -1926607734)
    a = hh(a, b, c, d, x[i + 5], 4, -378558)
    d = hh(d, a, b, c, x[i + 8], 11, -2022574463)
    c = hh(c, d, a, b, x[i + 11], 16, 1839030562)
    b = hh(b, c, d, a, x[i + 14], 23, -35309556)
    a = hh(a, b, c, d, x[i + 1], 4, -1530992060)
    d = hh(d, a, b, c, x[i + 4], 11, 1272893353)
    c = hh(c, d, a, b, x[i + 7], 16, -155497632)
    b = hh(b, c, d, a, x[i + 10], 23, -1094730640)
    a = hh(a, b, c, d, x[i + 13], 4, 681279174)
    d = hh(d, a, b, c, x[i], 11, -358537222)
    c = hh(c, d, a, b, x[i + 3], 16, -722521979)
    b = hh(b, c, d, a, x[i + 6], 23, 76029189)
    a = hh(a, b, c, d, x[i + 9], 4, -640364487)
    d = hh(d, a, b, c, x[i + 12], 11, -421815835)
    c = hh(c, d, a, b, x[i + 15], 16, 530742520)
    b = hh(b, c, d, a, x[i + 2], 23, -995338651)
    a = ii(a, b, c, d, x[i], 6, -198630844)
    d = ii(d, a, b, c, x[i + 7], 10, 1126891415)
    c = ii(c, d, a, b, x[i + 14], 15, -1416354905)
    b = ii(b, c, d, a, x[i + 5], 21, -57434055)
    a = ii(a, b, c, d, x[i + 12], 6, 1700485571)
    d = ii(d, a, b, c, x[i + 3], 10, -1894986606)
    c = ii(c, d, a, b, x[i + 10], 15, -1051523)
    b = ii(b, c, d, a, x[i + 1], 21, -2054922799)
    a = ii(a, b, c, d, x[i + 8], 6, 1873313359)
    d = ii(d, a, b, c, x[i + 15], 10, -30611744)
    c = ii(c, d, a, b, x[i + 6], 15, -1560198380)
    b = ii(b, c, d, a, x[i + 13], 21, 1309151649)
    a = ii(a, b, c, d, x[i + 4], 6, -145523070)
    d = ii(d, a, b, c, x[i + 11], 10, -1120210379)
    c = ii(c, d, a, b, x[i + 2], 15, 718787259)
    b = ii(b, c, d, a, x[i + 9], 21, -343485551)
    a = ad(a, olda)
    b = ad(b, oldb)
    c = ad(c, oldc)
    d = ad(d, oldd)
  }
  return rh(a) + rh(b) + rh(c) + rh(d)
}

function exportExcel (dataResult, result, percentResult) {

  // TO remove undefined value :
  dataResult.map(el => {
    Object.keys(el).map(element => {
      if (el[element] === undefined) {
        delete el[element]
      }
    })
  })

  let today = new Date()
  let date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()
  if (dataResult?.length) {
    const ws = XLSX.utils.json_to_sheet(dataResult)
    const ws2 = XLSX.utils.json_to_sheet(percentResult)
    // const workSheet = XLSX.utils.json_to_sheet(dataResult);
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, 'Trefferanzahl')
    XLSX.utils.book_append_sheet(wb, ws2, 'Prozentwerte')
    const bin = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    downloadBufferAsFile(`${result}${date}`, bin)
  }
}

function downloadBufferAsFile (filename, bin, textType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
  const element = document.createElement('a')
  const file = new Blob([bin], { type: textType })
  element.href = URL.createObjectURL(file)
  element.download = filename
  document.body.appendChild(element) // Required for this to work in FireFox
  element.click()
}

function downloadTextAsFile (filename, text, textType = 'text/plain') {
  const pom = document.createElement('a')
  pom.setAttribute('href', 'data:' + textType + ';charset=utf-8,' + encodeURIComponent(text))
  pom.setAttribute('download', filename)

  if (document.createEvent) {
    const event = document.createEvent('MouseEvents')
    event.initEvent('click', true, true)
    pom.dispatchEvent(event)
  } else {
    pom.click()
  }
}

function saveFileBlob (name, type, dataBlob) {
  const url = window.URL.createObjectURL(dataBlob)
  const a = document.createElement('a')
  a.setAttribute('href', url)
  a.setAttribute('download', name)
  a.click()
  window.URL.revokeObjectURL(url)
  a.remove()
}

/*    callback?: (doc: jsPDF) => void;
    margin?: number | number[];
    autoPaging?: boolean | "slice" | "text";
    filename?: string;
    image?: HTMLOptionImage;
    html2canvas?: Html2CanvasOptions;
    jsPDF?: jsPDF;
    x?: number;
    y?: number;
    width?: number;
    windowWidth?: number;
    fontFaces?: HTMLFontFace[];*/

function uploadAsFile (filename, text, ext, title = '') {
  if (ext === 'pdf') {
    /** temporary disabled **/
    /*
    const doc = jsPDF('p', 'pt', 'a4');
    // We'll make our own renderer to skip this editor
    const options = {
        margin: 15,
        autoPaging:"slice",
        width: 600,
        windowWidth: 600,
        callback: function (pdf) {
            const iframe = document.createElement('iframe');
            iframe.setAttribute('style', 'position:absolute;right:0; top:0; bottom:0; height:100%; width:500px');
            document.body.appendChild(iframe);
            iframe.src = pdf.output('datauristring');
        }

    }
    doc.html(element, options).save('download.'+ext);
    */

  } else if (ext === 'html') {
    const header = `<html lang='de'><head><meta charset='utf-8'><title>${title}</title></head><body>`
    const footer = '</body></html>'
    const blob = new Blob(['\ufeff', header + text + footer], {
      type: 'text/html'
    })
    saveFileBlob('download.' + ext, 'text/html', blob)
  } else if (ext === 'txt') {
    const res = text.replaceAll(/<br\s*\/?>/g, '\n').replaceAll(/<\/[ph][\d]*>/g, '\n\n').replaceAll(/<[^>]+>/g, '')    
    const blob = new Blob(['\ufeff', res], {
      type: 'text/plain'
    })
    saveFileBlob('download.' + ext, 'text/plain', blob)
  } else if (ext === 'doc') {
    const header = '<html lang=\'de\' xmlns:o=\'urn:schemas-microsoft-com:office:office\' xmlns:w=\'urn:schemas-microsoft-com:office:word\' xmlns=\'http://www.w3.org/TR/REC-html40\'>' +
      `<head><meta charset='utf-8'><title>${title}</title></head><body>`
    const footer = '</body></html>'
    const blob = new Blob(['\ufeff', header + text + footer], {
      type: 'application/msword'
    })
    saveFileBlob('download.' + ext, 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', blob)

  } else if (ext === 'docx') {
    const header = `<html lang='de'><head><meta charset='utf-8'><title>${title}</title></head><body>`
    const footer = '</body></html>'
    const res = text.replaceAll(/&nbsp;&nbsp;&nbsp;/g, '\t')
    asBlob(header + res + footer).then(res =>
      saveFileBlob('download.' + ext, 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', res))
  }

}

function convertPipeToNewLine (text = '', html = false) {
  return html ? text.replace(/\|/g, '</br>') : text.replace(/\|/g, '\n')
}

function filterByHIXCLIXFlesch (filter, clix, valueName) {
  if (filter['hix'] && filter['clix']) {
    return config.hixFleschFilter.indexOf(valueName) === -1 && (!clix || clix === 0)
  }
  if (filter['flesch'] && filter['clix']) {
    return config.hixFleschFilter.indexOf(valueName) === -1 && (!clix || clix === 0)
  }
  if (filter['hix'] && config.hixFleschFilter.indexOf(valueName) === -1) {
    return true
  }
  if (filter['flesch'] && config.hixFleschFilter.indexOf(valueName) === -1) {
    return true
  }
  return filter['clix'] && (!clix || clix === 0)
}

function convertNumberToStringLocally (num, lang) {

  const convertedLanguage = convertLanguagesToFull(lang, true)
  //console.log('convertNumberToStringLocally', num, lang, typeof num, convertedLanguage )
  return convertedLanguage && typeof num === 'number' ?
    num.toLocaleString(convertedLanguage) : num
}

function setAutoPosition (clientHeight, clientWidth, windowInnerHeight, windowInnerWidth, top, left) {
  let newY
  let newX
  if (clientHeight + top > windowInnerHeight - 80) {
    newY = top - clientHeight / 2 - 50
  } else {
    newY = top + clientHeight / 2
  }

  if (clientWidth + left > windowInnerWidth) {
    newX = left - clientWidth / 2
  } else {
    newX = left + 50
  }
  return [newX, newY]
}

function countTermsWithPositions (value = [], type = 'terms') {
  if (!value || !value?.length)
    return 0
  if (type === 'terms')
    return value.reduce((acc, val) => acc + val?.position?.length, 0)
  if (type === 'array')
    return value.length
  return 0
}

function textAddBodyBorders (text, resultData, offset = 0) {
  text = removeBodyBorders(text)

  if (resultData?.dataBodyRecognition) {
    const dataBodyRecognition = resultData.dataBodyRecognition
    if (dataBodyRecognition[1] > -1) {
      text = text.substring(0, dataBodyRecognition[1] + offset) + `<span class="bodyborder">--------</span>` + text.substring(dataBodyRecognition[1] + offset)
    }
    if (dataBodyRecognition[0] > -1) {
      text = text.substring(0, dataBodyRecognition[0]) + `<span class="bodyborder">--------</span>` + text.substring(dataBodyRecognition[0])
    }
  }
  return text
}

function arrayAddBodyBorders (splittedText, resultData) {
  if (resultData?.dataBodyRecognition) {
    const dataBodyRecognition = resultData.dataBodyRecognition
    if (dataBodyRecognition[1] > -1) {
      splittedText[splittedText.length - 1].post = `<span class="bodyborder">--------</span>` + splittedText[splittedText.length - 1].post
    }
    if (dataBodyRecognition[0] > -1) {
      splittedText[0].pre += `<span class="bodyborder">--------</span>`
    }
  }
  return splittedText
}

function prepareReplacement (replacement) {
  let returnReplacements = {
    false: {
      fullLength: replacement.length,
      meaning: false,
      replacements: []
    }
  }

  if (replacement.length > 0) {
    for (let index in replacement) {
      const { description, lemma, words, tag, settings } = replacement[index]
      const fullMeaning = settings?.meaning ? settings.meaning : []

      if (fullMeaning.length > 0) {
        for (const meaning of fullMeaning) {
          if (meaning && meaning.length > 0) {
            if (returnReplacements[JSON.stringify(meaning)] === undefined) {
              returnReplacements[JSON.stringify(meaning)] = {
                meaning: [convertArrayToText(meaning[0]), meaning[1] !== undefined ? meaning[1] : ''],
                replacements: []
              }
            }
            returnReplacements[JSON.stringify(meaning)].replacements.push({
              description,
              settings: settings,
              lemma,
              words,
              tag,
              index
            })
          }
        }
      } else {
        returnReplacements[false].replacements.push({
          description,
          settings: settings,
          lemma,
          words,
          tag,
          index
        })

      }
    }
  }
  return returnReplacements

}

/*{
	"0": {
		"company_id": 0,
		"create_time": "2022-09-14 07:33:10:UTC",
		"folder_name": "RuV Test",
		"id": 80,
		"parent": 0,
		"user_group_id": 0,
		"user_id": 2
	}
}*/
function recursiveAddFolder (returnArray, folder) {
  for (let i in returnArray) {
    if (returnArray[i].id === folder.parent) {
      returnArray[i].child.push({ ...folder, child: [] })
      return returnArray
    }
    if (returnArray[i]?.child?.length) {
      returnArray[i].child = recursiveAddFolder(returnArray[i].child, folder)
      return returnArray
    }
  }
  return returnArray
}

function convertFoldersToArray (data) {
  let returnArray = []
  for (let folder of data) {
    if (folder.parent === 0) {
      returnArray.push({ ...folder, child: [] })
    } else {
      returnArray = recursiveAddFolder(returnArray, folder)
    }
  }
  return returnArray
}

function readUploadedFileAsBinary (inputFile, isText = false) {
  const temporaryFileReader = new FileReader()

  return new Promise((resolve, reject) => {

    temporaryFileReader.onerror = () => {
      temporaryFileReader.abort()
      reject(new DOMException('Problem parsing input file.'))
    }

    temporaryFileReader.onload = () => {
      if (!isText) {
        resolve(temporaryFileReader.result)
        return
      }
      const result = new TextDecoder('utf8').decode(temporaryFileReader.result)
      for (const stringVal of result) {
        if (stringVal.charCodeAt(0) === 65533) {
          resolve(new TextDecoder('cp1252').decode(temporaryFileReader.result))
          return
        }
      }
      resolve(result)
    }
    temporaryFileReader.readAsArrayBuffer(inputFile)
  })
}

function textAddDataIgnoredArray (splittedText, resultData, translate) {
  for (const ignore of resultData.dataIgnoredText) {
    const to = resultData.dataTokenizer.findIndex(v => v.begin >= ignore[0])
    if (to > -1) {
      if (to === 0) {
        splittedText[0]['pre'] = '<mark class="bgray" title="' + translate('ignore_hover_text') + '">' + splittedText[0]['pre'] + '</mark>'
      } else {
        const from = to - 1
        splittedText[from]['text'] = splittedText[from]['text'] + '<mark class="bgray" title="' + translate('ignore_hover_text') + '">'
        splittedText[to]['text'] = '</mark>' + splittedText[to]['text']
      }
    }
  }
  return splittedText
}

const textClear = (text) => {
  return text.replace(/<\/p>$/, '').replaceAll(/<\/p>/g, '\n\n').replaceAll(/<\/h\d>/g, '\n\n').replaceAll(/<br *\/*>/g, '\n').replace(/<\/?[^>]+(>|$)/g, '')
}

const checkAiText = (text, maxChar, section) => {
  const max = maxChar[section] !== undefined ? maxChar[section] : config.aiDefaultTextSize
  return textClear(text).length <= max
}

async function getTreeTaggerResult (tlService, token, categoryLocaleName, element) {
  try {
    const replacementRes = await tlService.getTreeTager(token, categoryLocaleName, element)
    const word = []
    const lemma = []
    const tag = []
    if (replacementRes?.dataTokenizer) {
      for (const el of replacementRes.dataTokenizer) {
        word.push(el.word)
        lemma.push(el.lemma)
        tag.push(el.tag)
      }
      return { word, lemma, tag }
    }
    return { error: { message: '{"message":"empty dataTokenizer"}' } }
  } catch (e) {
    console.log(e)
    return { error: e }
  }
}

function parseJwt (token) {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(
    window.atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
    }).join(''))
  try {
    return JSON.parse(jsonPayload)
  } catch (e) {
    return { exp: '' }
  }
}

const convertedValue = (value, type, i18nLanguage, t, benchmarks = {}, row={}, right) => {
  switch (type) {
    case 'string':
      return typeof value === 'string' ? value : value.toString()
    case 'numeric':
      return value === undefined ? '-' : roundWithPrecision(value, 2)
    case 'date':
      return value ? printConvertedDate(convertUTCDateToLocalDate(value), i18nLanguage) : '-'
    case 'boolean':
      return value ? <FiberManualRecordRounded style={{ color: '#3CC13B' }} fontSize="small"/> :
        <FiberManualRecordRounded style={{ color: '#7D7D7D' }} fontSize="small"/>
    case 'benchmark':
      if (benchmarks[value] !== undefined) {
        return benchmarks[value].name[convertLanguagesToShort(i18nLanguage)]
      } else {
        return t('textype is not enabled')
      }
    case 'array':
      return value.length ? value.join(', ') : '-'
    case 'term_count':
      return <div className='d-flex align-items-center' style={{ marginLeft: "100px" }}>
        <NavLink to={`/administration/terminology/update/${row.id}`} className='mr-2'>
          <Button className={`p-0`} style={{ minWidth: 35, height: 35 }} size='small' variant='contained'
            color="primary">
            <Menu />
          </Button>
        </NavLink>
        {value}
      </div>
    case 'link':
      if (right && !row.user_deleted) {
        return <NavLink to={`/administration/user/update/${value}`} className='mr-2'
          style={{ width: 120, color: "#415C98", cursor: 'pointer' }}>{value}</NavLink>
      } else {
        return value
      }
    default:
      return value
  }
}

async function getTCLocaleName (tlService, token, id) {
  const filter = {
    return_values: JSON.stringify(['id', 'locale_name']),
    id
  }
  try {
    const respond = await tlService.getFilteredTermCategories(token, {}, filter)
    if (respond && respond?.data?.length) {
      return respond?.data[0]?.locale_name
    }
  } catch (err) {
    console.log(err)
  }
  return undefined
}

async function checkSharedData (tlService, token, row, t, type = "user") {
  let termCategories
  let textBins = {}
  let allowedLists = {}
  let usersInGroup = {}
  let groupsInCompany = {}
  let sharedArchivesInGroup = {}
  let benchmarks = {}
  let foldersGroup = [{ name: t('Default folder'), id: 0 }]
  let archiveFoundInFolderGroup = []
  let sharedArchivesInCompany = {}
  let foldersCompany = [{ name: t('Default folder'), id: 0 }]
  let archiveFoundInFolderCompany = []

  const isUser = type === "user"
  const group_id = isUser ? row.user_group_id : row.id


  if (!(type === 'company')) {

    try {
      const res = await tlService.getFilteredArchiveFolders(token, {}, { user_group_id: group_id })
      if (res?.data?.length > 0) {
        res.data.forEach(folder => {
          foldersGroup.push({ name: folder.folder_name, id: folder.id })
        })
      }
    }
    catch (error) {
      console.log('getArchiveFolders', error)
    }
    
    try {
      sharedArchivesInGroup = await tlService.getFilteredArchive(token, {}, {
        user_group_id: group_id,
        extended_filter: isUser ? `[["&owner","=${row.id}"]]` : '',
        return_values: JSON.stringify(['folder_id', 'id'])
      })
  
      if (sharedArchivesInGroup?.data?.length > 0) {
        foldersGroup.forEach(folder => {
          const foundFolder = sharedArchivesInGroup.data.find(archive => archive.folder_id === folder.id)
          if (foundFolder) {
            archiveFoundInFolderGroup.push(folder.name)
          }
        }
        )
      }
    } catch (error) {
      console.log('getSharedArchives', error)
    }
  }
 
  if (!(type === 'group')) {

  try {
    const res = await tlService.getFilteredArchiveFolders(token, {}, { company_id: isUser ? row.company_id : row.id })
    if (res?.data?.length > 0) {
      res.data.forEach(folder => {
        foldersCompany.push({ name: folder.folder_name, id: folder.id })
      })
    }
  }
  catch (error) {
    console.log('getArchiveFolders', error)
  }

  try {
    sharedArchivesInCompany = await tlService.getFilteredArchive(token, {}, {
      company_id: isUser ? row.company_id : row.id,
      extended_filter: isUser ? `[["&owner","=${row.id}"]]` : '',
      return_values: JSON.stringify(['folder_id', 'id'])
    })

    if (sharedArchivesInCompany?.data?.length > 0) {
      foldersCompany.forEach(folder => {
        const foundFolder = sharedArchivesInCompany.data.find(archive => archive.folder_id === folder.id)
        if (foundFolder) {
          archiveFoundInFolderCompany.push(folder.name)
        }
      }
      )
    }
  } catch (error) {
    console.log('getSharedArchives', error)
  }
 
 } 

  if (type === 'group') {
    try {
      usersInGroup = await tlService.getFilteredUsers(token, { limit: 1 }, {
        user_group_id: group_id,
        enabled: 1
      })
    } catch (error) {
      console.log("getGroupUsers", error)
    }
  }
 
  if (type === 'company') {
    try {
      groupsInCompany = await tlService.getFilteredGroups(token, {limit:1}, {
        company_id: group_id
      })
    } catch (error) {
      console.log("getCompanyGroups", error)
      
    }
  }

  try {
    let filterOptions = { return_values: JSON.stringify(['id']) }

    if (type === "user") {
      filterOptions.user_id = row.id
    } else if (type === "group") {
      filterOptions.user_group_id = group_id
    } else {
      filterOptions.company_id = group_id
    }

    termCategories = await tlService.getFilteredTermCategories(token, { limit: 1 }, filterOptions)
  } catch (error) {
    console.log('getTermCategories', error)
  }

  try {
    let filterOptions = { return_values: JSON.stringify(['id']) }
    if (type === "user") {
      filterOptions.extended_filter = JSON.stringify([["&user_id", `=${row.id}`]])
    } else if (type === "group") {
      filterOptions.extended_filter = JSON.stringify([["&user_group_id", `=${group_id}`]])
    } else {
      filterOptions.extended_filter = JSON.stringify([["&company_id", `=${group_id}`]])
    }
    textBins = await tlService.getFilteredTextbin(token, { limit: 1 }, filterOptions)


  }
  catch (error) {
    console.log('getTextBins', error)
  }

  try {
    let filterOptions = { return_values: JSON.stringify(['id']) }
    if (type === "user") {
      filterOptions.extended_filter = JSON.stringify([["&user_id", `=${row.id}`]])
    } else if (type === "group") {
      filterOptions.extended_filter = JSON.stringify([["&user_group_id", `=${group_id}`]])
    } else {
      filterOptions.extended_filter = JSON.stringify([["&company_id", `=${group_id}`]])
    }
    allowedLists = await tlService.getFilteredAllowedList(token, { limit: 1 }, filterOptions)
  }
  catch (error) {
    console.log('getAllowedLists', error)
  }

  try{
    let filterOptions = { return_values: JSON.stringify(['id']) }
    if (type === "user") {
      filterOptions.extended_filter = JSON.stringify([["&user_id", `=${row.id}`]])
    } else if (type === "group") {
      filterOptions.extended_filter = JSON.stringify([["&user_group_id", `=${group_id}`]])
    } else {
      filterOptions.extended_filter = JSON.stringify([["&company_id", `=${group_id}`]])
    }
    benchmarks = await tlService.getFilteredBenchmarks(token, {limit:1}, filterOptions)
  } 
  catch (error) {
    console.log('getBenchmarks', error)
  }

  return { textBins, allowedLists, sharedArchivesInGroup, sharedArchivesInCompany, archiveFoundInFolderGroup, archiveFoundInFolderCompany, benchmarksInGroup: benchmarks, termCategories, usersInGroup, groupsInCompany }
}

const areasWithoutRights = (group_id, checks, user) => {
  const prefix = 'admin_ugc_disabled-button-tooltip_missing-right'
  let areas = []
  for (const { fullcount, companyLevelRight, groupRight, area, companyOnly = false } of checks) {
    if (!fullcount && !checkUserRight(user, [companyLevelRight])) {
      if(companyOnly){
        areas.push(`${prefix}_${area}`)     
      } else {
      const hasRight = user?.user_rights?.find(
        (right) => right.user_group_id === group_id && right.right_id === groupRight
      )
      if (!hasRight) {
        areas.push(`${prefix}_${area}`)
      }
    }
    }
  }
  return areas
}

const getColBasinLocaleName = (lists, row, i18nLanguage) => {
  const list = lists.find(l => l.id === row.term_category_id)
  return list ? convertLanguagesToShort(list.locale_name) : convertLanguagesToShort(i18nLanguage)
}

function applyReplacements (innerReplacements, words, arrText){
  const copyWords = [...words]
  for (let i = 0; i < innerReplacements.length; i++) {
    let repWord = ''
    if (arrText[innerReplacements[i][2]] && arrText[innerReplacements[i][3]]){
      if (innerReplacements[i][2] === innerReplacements[i][3]){
        repWord = arrText[innerReplacements[i][2]].substring(innerReplacements[i][0], innerReplacements[i][1]+1)
      } else {
        repWord = arrText[innerReplacements[i][2]].substring(innerReplacements[i][0])
        repWord += arrText[innerReplacements[i][3]].substring(0, innerReplacements[i][1]+1)
      }
      for (let j = 0; j < copyWords.length; j++) {
        copyWords[j] = copyWords[j].replaceAll(`$${i+1}`, repWord)
      }
    }
  }
  return copyWords
}



/*
function fixEncoding (text) {
    const windows1252Table = {
        '\u00FC': '\u00c3\u00bc',
        '\u00DC': '\u00c3\u009c',
        '\u00F6': '\u00c3\u00b6',
        '\u00D6': '\u00c3\u0096',
        '\u00E4': '\u00c3\u00a4',
        '\u00C4': '\u00c3\u0084',
        '\u00DF': '\u00c3\u009f',
    }
    const windows1252Regex = RegExp(
      '(\u00FC|\u00DC|\u00F6|\u00D6|\u00E4|\u00C4|\u00DF)',
      'g')
    return text.replaceAll(windows1252Regex, x => windows1252Table[x])
}
*/

export {
  applyReplacements,
  getTCLocaleName,
  dateToSearchString,
  parseJwt,
  setFunctionsByReturnValueType,
  returnValuesFilter,
  checkClixNotZero,
  getTreeTaggerResult,
  textAddDataIgnoredArray,
  readUploadedFileAsBinary,
  downloadBufferAsFile,
  convertFoldersToArray,
  showStateAsText,
  md5Generator,
  convertFromErrorObject,
  prepareReplacement,
  getValue,
  getScale,
  removeBodyBorders,
  textAddBodyBorders,
  arrayAddBodyBorders,
  countTermsWithPositions,
  convertNumberToStringLocally,
  filterByHIXCLIXFlesch,
  convertPipeToNewLine,
  getResource, appendLimits, appendFilters,
  isObjectsEqual,
  chooseColor,
  findFirstDiff,
  mammothAsync, readFileAsync,
  checkTextSize,
  stripHtml,
  textClear,
  checkAiText,
  compose,
  checkUserRight,
  convertLanguagesToFull,
  convertPlainToHTML,
  convertLanguagesToShort,
  removeTagMark,
  setLanguage,
  sleep,
  cumulativeOffset,
  onlyUnique,
  deepCopy,
  convertedValue,
  convertArrayToText,
  createDefaultMultilangObject,
  getDataFromModals,
  getModalFromModals,
  convertTextToTT,
  removeEmptyFromObject,
  log,
  isObjectEmpty,
  roundWithPrecision,
  convertJSONToObject,
  getListByColor,
  findToNotHTML,
  getPositionNotHTML,
  downloadTextAsFile,
  uploadAsFile,
  setAutoPosition,
  exportExcel,
  convertUTCDateToLocalDate,
  printConvertedDate,
  deprecatedBenchmarkCheck,
  clearHtml,
  parseErrorObject,
  checkSharedData,
  areasWithoutRights,
  checkGroupRight,
  getColBasinLocaleName
}
