import React, { useEffect, useState } from 'react'
import style from './style.module.sass'
import arrow from '../../../Profile/ProfileWrapper/assets/arrow.svg'
import { NavLink } from 'react-router-dom'
import { useTheme } from '@material-ui/core/styles'
import userIcon from '../assets/userIcon.svg'
import {
  Button, Dialog,
  DialogActions,
  DialogContent,
  DialogContentText
} from '@material-ui/core'
import saveIcon from '../assets/saveIcon.svg'
import { useFormik } from 'formik'
import * as yup from 'yup'
import UserData from './UserData/UserData'
import TextLabStatus from './TextLab-Status/TextLabStatus'
import Options from './Options/Options'
import UserRights from './UserRights/UserRights'
import { useTranslation } from 'react-i18next'
import TLServiceComponent from '../../../../contexts/TLServiceComponent'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { checkUserRight, checkSharedData, convertFromErrorObject, isObjectsEqual, isObjectEmpty, areasWithoutRights } from '../../../../utils'
import config from '../../../../config'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Spinner from '../../../Spinner'
import RequestComponent from '../../User/Table/TableInnerComponent/ReqComponent'
import { useActions } from '../../../../utils/action-helper'
import { tableSetDialog } from '../../../../actions'

function Form () {
  const { theme: { bgColors, colors } } = useTheme()
  const tlService = React.useContext(TLServiceComponent)
  const token = useSelector((state) => state.userData.token)
  const passwordRegex = useSelector((state) => state.userData.user.password_regex) || '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$'
  const user = useSelector(state => state.userData.user)
  const dialog = useSelector(state => state.tableData.dialog)
  const dialogOpen = useSelector(state => state.tableData.dialog.open)
  const DialogRequestComponent = useSelector(state => state.tableData.dialog.RequestComponent)
  const dialogButtons = useSelector(state => state.tableData.dialog.buttons)

  const { id } = useParams()
  const { t } = useTranslation()
  const [companyList, setCompanyList] = useState([])
  const [groupList, setGroupList] = useState([])
  const [optionList, setOptionList] = useState([])
  const [rightIs, setRightIs] = useState([])
  const [userRights, setUserRights] = useState([])
  const [initialRights, setInitialRights] = useState([])
  const [spinner, setSpinner] = useState(false)
  const [users, setUsers] = useState([])
  const [submitted, setSubmitted] = useState(false)

  const { aTableSetDialog } = useActions({ aTableSetDialog: tableSetDialog })

  //state validation by YUP
  const validationSchema = yup.object({
    isAddingUser: yup.boolean(),
    username: yup
      .string('Enter your username')
      .required(t('admin_user_username-is-required')),
    password: yup
      .string('Enter your password')
      .when('isAddingUser', {
        is: true,
        then: yup.string().required(t('admin_user_password-is-required')),
        otherwise: yup.string()
      })
      .matches(RegExp(passwordRegex), 'password_check_regexp_wrong')
      .notOneOf([yup.ref('username')], 'not-match-your-username'),
    company: yup
      .string('Enter your company')
      .required(t('admin_user_company-is-required')),
    userGroup: yup
      .string('Enter your user group')
      .required(t('admin_user_user-group-is-required')),
    typeOfUser: yup
      .string('Enter your type of user')
      .required(t('admin_user_type-of-user-is-required')),
    repeatedPassword: yup
      .string('Enter your password')
      .when('isAddingUser', {
        is: true,
        then: yup.string().required(t('admin_user_password-is-required')),
        otherwise: yup.string()
      })
      .oneOf([yup.ref('password'), null], 'passwords-must-match'),
  })

  const formik = useFormik({
    initialValues: {
      id: '',
      isAddingUser: !id,
      username: '',
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      repeatedPassword: '',
      expiryDate: null,
      company: '',
      userGroup: '',
      typeOfUser: '',
      user_group_id: '',
      country_id: 'DE',
      user_type_id: '',
      language_id: 'de',
      street: '',
      house_number: '',
      zip_code: '',
      city: '',
      license_id: 0,

      enabled: false,
      termsAndConditions: false,
      changePassword: false,

      // OPTIONS VALUE:
      maximum_users: '',
      enabled_benchmark_templates: '',
      edit_archives: '',
      default_language: '',
      analyse_language_texttype: '',
      enabled_frontend_sections: '',
      enabled_export_template: '',
      export_template_word: '',
      enable_textbin: '',
      default_company_settings: '',
      enabled_texttypes: '',
      view_archives: '',
      color_schema: '',
      analyse_tonality: '',
      tonality_visibility: '',
      export_template_single: '',
      export_template_excel: '',
      modal_settings: '',
      expired_time: null,

      // OPTIONS ENDS:

      // User Rights VALUE start:
      MayViewListOfUsersInAGroup: false,
      MayCreateUsersInAGroup: false,
      MayChangeDeleteUsersInAGroup: false,
      UserOptionsInAGroup: false,
      UserRightInAGroup: false,
      MayViewListOfSpecificGroups: false,
      MayAddChangeDeleteGroupsOptionsForSpecificGroups: false,
      MayAddDeleteMessagesInAGroup: false,
      MayViewArchiveAndTagsFolderInAGroup: false,
      MayChangeDeleteArchiveAndTagsFolderInAGroup: false,
      MayViewUpdateApproveAllowListsInAGroup: false,
      MayViewUpdateApproveTextbinInAGroup: false,

      MayViewListOfUsersInACompany: false,
      MayCreateUsersInACompany: false,
      MayChangeDeleteUsersInACompany: false,
      MayChangeDeleteUserOptionsInACompany: false,
      MayChangeDeleteUserRightInACompany: false,
      MayViewListOfGroupsInACompany: false,
      MayCreateGroupsInACompany: false,
      MayChangeDeleteGroupsInACompany: false,
      MayAddChangeDeleteGroupsOptionsInACompany: false,
      MayAddDeleteMessagesInACompany: false,
      MayViewArchiveAndTagsFolderInACompany: false,
      MayChangeDeleteArchiveAndTagsFolderInACompany: false,
      MayViewUpdateApproveAllowListsInACompany: false,
      MayViewUpdateApproveTextbinInACompany: false,

      MayViewListOfCompanies: false,
      MayCreateACompany: false,
      MayChangeDeleteACompany: false,
      MayChangeDeleteACompanyOptions: false,

      MaySetUserAdministrateRightsOperationAndCreateEditDeleteAdmins: false,
      MaySetUserLicenseType: false,
      MaySeeLogs: false,
      // User Rights VALUE ENDS:

      showSpelling: true,
      showArchive: true,
      saveTextAfterSession: true,
      defaultBenchmark: 1,
      defaultLanguage: 1,
      emailNotificationOnWord: 1,
      //--------------//
      mayUpdateAResGroupMem: true,
      mayAdminBasicVocabulary: true,
      mayAdminGeneralTerminology: true,
      mayAdminControlList: true,
      mayAdminUserRights: true,
      mayAddUserToGroup: true,
      mayCRUDUserGroup: true,
      mayChangeUsersName: true,
      mayBlockUsers: true,
      mayEditTextType: true,
      mayViewLogs: true,
      mayAdminTerms: true,
      mustAcceptTerms: true,
      mustChangePassLogin: true,
      mayUpdateAResGroup: true,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // alert(JSON.stringify(values, null, 2));
      postUser(values)
    },
  })

  const fillFormik = async () => {
    let filterIs
    let users
    let messages
    filterIs = { id: id }
    if (!!id) {
      try {
        // API call here
        console.log('filterIs', filterIs)
        users = await tlService.getUser(token, {}, filterIs)
        setUsers(users)
      } catch (e) {
        console.log('error', e)
        let error
      try {
        error = JSON.parse(e.message)
      } catch (e) {
        error = {}
      }
         toast.error(`${
          error.number === 404 ? t('admin_user_user-not-found') : convertFromErrorObject(t, e)
         }`)
        setTimeout(() => {
          window.location.href = '/administration'
        }, 5000)  
        return false
      }

      try {
        messages = await tlService.getAllMessages(token, users.data[0].id)
        // Setting message or textlab status checks:
        messages.data.forEach(el => {
          if (el.template === 'must_change_password' && el.read_time === null) {
            formik.initialValues.changePassword = true
          }
          if (el.template === 'terms_and_conditions' && el.read_time === null) {
            formik.initialValues.termsAndConditions = true
          }
        })
      } catch (error) {
        console.log('error', error)
      }

      // Set the Values if User try to EDIT:
      formik.initialValues.id = users.data[0].id
      formik.initialValues.username = users.data[0].user_name
      formik.initialValues.email = users.data[0].email
      formik.initialValues.firstName = users.data[0].first_name
      formik.initialValues.lastName = users.data[0].last_name
      formik.initialValues.company = users.data[0].company_id
      formik.initialValues.userGroup = users.data[0].user_group_id
      formik.initialValues.typeOfUser = users.data[0].user_type_id
      formik.initialValues.user_group_id = users.data[0].user_group_id
      formik.initialValues.country_id = users.data[0].country_id
      formik.initialValues.user_type_id = users.data[0].user_type_id
      formik.initialValues.language_id = users.data[0].language_id
      formik.initialValues.street = users.data[0].street
      formik.initialValues.house_number = users.data[0].house_number
      formik.initialValues.zip_code = users.data[0].zip_code
      formik.initialValues.city = users.data[0].city
      formik.initialValues.enabled = users.data[0].enabled === 1
      formik.initialValues.license_id = users.data[0].license_id
      // OPTIONS STARTS:
      formik.initialValues.maximum_users = !!users.data[0].user_options['maximum_users'] ? users.data[0].user_options['maximum_users'] : ''
      formik.initialValues.enabled_benchmark_templates = !!users.data[0].user_options['enabled_benchmark_templates'] ? users.data[0].user_options['enabled_benchmark_templates'] : ''
      formik.initialValues.edit_archives = !!users.data[0].user_options['edit_archives'] ? users.data[0].user_options['edit_archives'] : ''
      formik.initialValues.default_language = !!users.data[0].user_options['default_language'] ? users.data[0].user_options['default_language'] : ''
      formik.initialValues.analyse_language_texttype = !!users.data[0].user_options['analyse_language_texttype'] ? users.data[0].user_options['analyse_language_texttype'] : ''
      formik.initialValues.enabled_frontend_sections = !!users.data[0].user_options['enabled_frontend_sections'] ? users.data[0].user_options['enabled_frontend_sections'] : ''
      formik.initialValues.enabled_export_template = !!users.data[0].user_options['enabled_export_template'] ? users.data[0].user_options['enabled_export_template'] : ''
      formik.initialValues.export_template_word = !!users.data[0].user_options['export_template_word'] ? users.data[0].user_options['export_template_word'] : ''
      formik.initialValues.enable_textbin = !!users.data[0].user_options['enable_textbin'] ? users.data[0].user_options['enable_textbin'] : ''
      formik.initialValues.default_company_settings = !!users.data[0].user_options['default_company_settings'] ? users.data[0].user_options['default_company_settings'] : ''
      formik.initialValues.enabled_texttypes = !!users.data[0].user_options['enabled_texttypes'] ? users.data[0].user_options['enabled_texttypes'] : ''
      formik.initialValues.view_archives = !!users.data[0].user_options['view_archives'] ? users.data[0].user_options['view_archives'] : ''
      formik.initialValues.color_schema = !!users.data[0].user_options['color_schema'] ? users.data[0].user_options['color_schema'] : ''
      formik.initialValues.analyse_tonality = !!users.data[0].user_options['analyse_tonality'] ? users.data[0].user_options['analyse_tonality'] : ''
      formik.initialValues.tonality_visibility = !!users.data[0].user_options['tonality_visibility'] ? users.data[0].user_options['tonality_visibility'] : ''
      formik.initialValues.export_template_single = !!users.data[0].user_options['export_template_single'] ? users.data[0].user_options['export_template_single'] : ''
      formik.initialValues.export_template_excel = !!users.data[0].user_options['export_template_excel'] ? users.data[0].user_options['export_template_excel'] : ''
      formik.initialValues.modal_settings = !!users.data[0].user_options['modal_settings'] ? users.data[0].user_options['modal_settings'] : ''
      formik.initialValues.expired_time = !!users.data[0].user_options['expired_time'] ? users.data[0].user_options['expired_time'] : null
      // OPTIONS ENDS:

      // SETTING USER RIGHTS:
      let arry = []
      const rights = users.data[0]?.user_rights?.map(r => [r.right_id, r.user_group_id])
      setInitialRights(rights)
      users.data[0].user_rights.forEach(el => {
        arry.push({
          id: el.right_id,
          user_group_id: el.user_group_id
        })
      })

      const userRightsArray = arry.filter(el => config.userRightsGroupLevel.includes(el.id))
      setUserRights(userRightsArray)

      userRightsArray.forEach(el => {
          formik.initialValues[`group_select_${el.id}`] = el.user_group_id
        }
      )

      Object.keys(config.userRights).forEach(el => {
        formik.initialValues[el] = arry.some(el2 => el2.id === config.userRights[el])
      })

    } else {
      // Set the Values to empty if User try to ADD new User:
      formik.initialValues.id = ''
      formik.initialValues.username = '' 
      formik.initialValues.email = ''
      formik.initialValues.firstName = ''
      formik.initialValues.lastName = ''
      formik.initialValues.company = ''
      formik.initialValues.userGroup = ''
      formik.initialValues.typeOfUser = ''
      formik.initialValues.user_group_id = ''
      formik.initialValues.country_id = 'DE'
      formik.initialValues.user_type_id = ''
      formik.initialValues.language_id = 'de'
      formik.initialValues.street = ''
      formik.initialValues.house_number = ''
      formik.initialValues.zip_code = ''
      formik.initialValues.city = ''
      formik.initialValues.enabled = false
      formik.initialValues.license_id = 0

      // Options start:
      formik.initialValues.maximum_users = ''
      formik.initialValues.enabled_benchmark_templates = ''
      formik.initialValues.edit_archives = ''
      formik.initialValues.default_language = ''
      formik.initialValues.analyse_language_texttype = ''
      formik.initialValues.enabled_frontend_sections = ''
      formik.initialValues.enabled_export_template = ''
      formik.initialValues.export_template_word = ''
      formik.initialValues.enable_textbin = ''
      formik.initialValues.default_company_settings = ''
      formik.initialValues.enabled_texttypes = ''
      formik.initialValues.view_archives = ''
      formik.initialValues.color_schema = ''
      formik.initialValues.analyse_tonality = ''
      formik.initialValues.tonality_visibility = ''
      formik.initialValues.export_template_single = ''
      formik.initialValues.export_template_excel = ''
      formik.initialValues.modal_settings = ''
      // Options ends:

      // User rights start:
      formik.initialValues.MayViewListOfUsersInAGroup = false
      formik.initialValues.MayCreateUsersInAGroup = false
      formik.initialValues.MayChangeDeleteUsersInAGroup = false
      formik.initialValues.UserOptionsInAGroup = false
      formik.initialValues.UserRightInAGroup = false
      formik.initialValues.MayViewListOfSpecificGroups = false
      formik.initialValues.MayAddChangeDeleteGroupsOptionsForSpecificGroups = false
      formik.initialValues.MayAddDeleteMessagesInAGroup = false
      formik.initialValues.MayViewArchiveAndTagsFolderInAGroup = false
      formik.initialValues.MayChangeDeleteArchiveAndTagsFolderInAGroup = false
      formik.initialValues.MayViewUpdateApproveAllowListsInAGroup = false
      formik.initialValues.MayViewUpdateApproveTextbinInAGroup = false

      formik.initialValues.MayViewListOfUsersInACompany = false
      formik.initialValues.MayCreateUsersInACompany = false
      formik.initialValues.MayChangeDeleteUsersInACompany = false
      formik.initialValues.MayChangeDeleteUserOptionsInACompany = false
      formik.initialValues.MayChangeDeleteUserRightInACompany = false
      formik.initialValues.MayViewListOfGroupsInACompany = false
      formik.initialValues.MayCreateGroupsInACompany = false
      formik.initialValues.MayChangeDeleteGroupsInACompany = false
      formik.initialValues.MayAddChangeDeleteGroupsOptionsInACompany = false
      formik.initialValues.MayAddDeleteMessagesInACompany = false
      formik.initialValues.MayViewArchiveAndTagsFolderInACompany = false
      formik.initialValues.MayChangeDeleteArchiveAndTagsFolderInACompany = false
      formik.initialValues.MayViewUpdateApproveAllowListsInACompany = false
      formik.initialValues.MayViewUpdateApproveTextbinInACompany = false

      formik.initialValues.MayViewListOfCompanies = false
      formik.initialValues.MayCreateACompany = false
      formik.initialValues.MayChangeDeleteACompany = false
      formik.initialValues.MayChangeDeleteACompanyOptions = false

      formik.initialValues.MaySetUserAdministrateRightsOperationAndCreateEditDeleteAdmins = false
      formik.initialValues.MaySetUserLicenseType = false
      formik.initialValues.MaySeeLogs = false
      // User right ends:
    }

    // Get company list:
    let company
    try {
      company = await tlService.getFilteredCompanies(token, {}, {})
    } catch (err) {
      console.log('error', err)
    }
    let companiesList = []
    !!company?.data && company.data.map((el) => {
      let obj = {}
      obj.id = el.id
      obj.name = el.name
      companiesList.push(obj)
    })
    setCompanyList(companiesList)
    let options
    try {
      options = await tlService.getAllOptions(token)
    } catch (err) {
      console.log('error', err)
    }
    if (options?.data) {
      // For Integer type, input number for text input type:
      options.data.map(el => {
        if (el.type === 'integer') {
          el.type = 'number'
        }
      })
      setOptionList(options.data)
    }
  }

  function isValidValue(value) {
    return value !== undefined && value !== null && value !== '' && (typeof value !== 'object' || Object.keys(value).length > 0);
  }

  useEffect(() => {
    fillFormik()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, submitted])

  const getGroup = async () => {
    let group
    if (!!formik.values.company) {
      try {
        group = await tlService.getFilteredGroups(token, {}, { company_id: formik.values.company })
      } catch (err) {
        console.log('error', err)
      }
      let groupList = []
      groupList.push({ 'id': '', 'name': t('select') })
      !!group?.data && group.data.map((el) => {
        let obj = {}
        obj.id = el.id
        obj.name = el.name
        groupList.push(obj)
      })
      setGroupList(groupList)
    }
  }

  // Get group according to company selected by User:
  useEffect(() => {
    getGroup()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.company])

  //reset user group id if company is changed
  useEffect(() => {
    const userCompanyId = users?.data?.[0]?.company_id
    if (users?.data?.length > 0 && formik.values.company !== userCompanyId) {
      formik.setFieldValue('userGroup', '')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.company])

  useEffect(() => {
    let rights = []
    // SET RIGHTS When User clicks:
    Object.keys(formik.values).forEach(el => {
      if (formik.values[el] === true) {
        rights.push(config.userRights[el])
      }
    })
    rights = rights.filter(list => list !== undefined)
    // add user group id to rights
    for (const list of rights) {
      const userGroupId = formik.values[`group_select_${list}`] || 0
      rights = rights.map(el => {
        return el === list ? [el, parseInt(userGroupId)] : el
      })
    }
    setRightIs(rights)
  }, [formik.values])

  const OptionRightMsg = async (values, idIs) => {
    const handleFieldChange = async (field, messageKey) => {
      if (formik.values[field] !== formik.initialValues[field]) {
        if (formik.values[field] === true) {
          try {
            await tlService.addMessage(token, idIs, 'alert', 'admin', messageKey, '')
          } catch (e) {
            console.log('error ' + convertFromErrorObject(t, e))
            formik.setFieldValue(field, formik.initialValues[field])
            toast.error(`${t('textlab-status')}/${t(messageKey)}: ${convertFromErrorObject(t, e)}`)
          }
        } else {
         formik.setFieldValue(field, formik.initialValues[field])
        }
      }
    }
      
    if (checkUserRight(user, [110, 210])) {
      await handleFieldChange('changePassword', 'must_change_password')
      await handleFieldChange('termsAndConditions', 'terms_and_conditions')
    }

    if (checkUserRight(user, [401])) {
      // User Rights:
    if (!isObjectEmpty(rightIs) && !isObjectsEqual(rightIs, initialRights)) {
      await tlService.deleteUserRight(token, idIs)
      if (rightIs.length > 0) {
        for (const list of rightIs) {
          try {
            const userGroupId = formik.values[`group_select_${list[0]}`] || values.userGroup
            await tlService.addUserRight(token, idIs, parseInt(list[0]),
              config.userRightsGroupLevel.includes(list[0]) ? parseInt(userGroupId) : 0)
          } catch (e) {
            console.log('error ' + convertFromErrorObject(t, e))
            toast.error(`${t("UserRights")}: ${convertFromErrorObject(t, e)}`)
          }
        }
      }
    }
    // Options:
    await tlService.deleteUserOption(token, idIs)
    for (const tmpOption of Object.keys(config.options)) {
      if (isValidValue(formik.values[tmpOption])) {
        const newValue = typeof formik.values[tmpOption] === 'object' ? JSON.stringify(formik.values[tmpOption]) : String(formik.values[tmpOption]).trim()
        if (newValue.length > 0) {
          try {
            await tlService.addUserOption(token, idIs, tmpOption, newValue)
          } catch (e) {
            console.log('error ' + convertFromErrorObject(t, e))
            toast.error(`${t("Options")}: ${convertFromErrorObject(t, e)}`)
            return false
          }
        }
      }
    }}
    setSubmitted(prev => !prev)
  }

  const postUser = async (values) => {
    if (!!id) {
      // EDIT
      const updateUser = async () => {
        try {
          await tlService.updateUser(token, values.id, {
            user_group_id: values.userGroup,
            country_id: values.country_id,
            user_type_id: values.typeOfUser,
            language_id: values.language_id,
            user_name: values.username,
            email: values.email,
            password: values.password || undefined,
            first_name: values.firstName,
            last_name: values.lastName,
            street: values.street,
            house_number: values.house_number,
            zip_code: values.zip_code,
            city: values.city,
            enabled: values.enabled === true ? 1 : 0,
            license_id: values.license_id,
            old_password: values.repeatedPassword
          })
          //  FOR EDIT, delete the previous rights first:
          OptionRightMsg(values, values.id)
          toast.success(t('admin_user_update-success-msg'))
        } catch (e) {
          console.log('error', e)
          toast.error(convertFromErrorObject(t, e))
        }
        return true
      }

      if (users.data[0].user_group_id !== values.userGroup && !dialogOpen) {
        setSpinner(true)
        let {termCategories, textBins, allowedLists, sharedArchivesInGroup, sharedArchivesInCompany, archiveFoundInFolderGroup, archiveFoundInFolderCompany } = await checkSharedData(tlService, token, users.data[0], t)
        setSpinner(false)
        const checks = [
          { fullcount: termCategories?.fullcount, companyLevelRight: 703, groupRight: 702, area: 'term-cats' },
          { fullcount: allowedLists?.fullcount, companyLevelRight: 231, groupRight: 131, area: 'allowed-lists'},
          { fullcount: textBins?.fullcount, companyLevelRight: 241, groupRight: 141, area: 'text-bins' },
          { fullcount: sharedArchivesInGroup?.archives_fullcount, companyLevelRight: 221, groupRight: 121, area: 'group-archives' }
        ]
        const areas = user.user_type_id === 1 ? [] : areasWithoutRights(users?.data[0]?.user_group_id, checks, user)

        if (termCategories?.fullcount > 0 || textBins?.fullcount > 0 || allowedLists?.fullcount > 0 || sharedArchivesInGroup.archives_fullcount > 0 || sharedArchivesInCompany.archives_fullcount > 0 || areas.length > 0) {
          aTableSetDialog({
            type: 'editUserWarning',
            open: true,
            RequestComponent: () => (
              <RequestComponent
                t={t}
                archiveFoundInFolderGroup={archiveFoundInFolderGroup}
                sharedArchivesInGroup={sharedArchivesInGroup}
                archiveFoundInFolderCompany={archiveFoundInFolderCompany}
                sharedArchivesInCompany={sharedArchivesInCompany}
                termCategories={termCategories}
                textBins={textBins}
                allowedLists={allowedLists}
                areas={areas}
                type='user'
                warningType='edit'
              />
            ),
            response: null,
            buttons: {
              yes: false,
              no: false,
              close: true,
              save: false,
              edit: true,
            }
          })
        } else {
          return updateUser()
        }
      } else {
        return updateUser()
      }
    }
    else {
      // ADD
      let res
      try {
        res = await tlService.addUser(token, values.userGroup,
          values.country_id, values.typeOfUser, values.language_id,
          values.username, values.email, values.password,
          values.firstName, values.lastName,
          values.street, values.house_number, values.zip_code,
          values.city, values.enabled === true ? 1 : 0, values.license_id
        )
        OptionRightMsg(values, res.id)
        toast.success(t('admin_user_add-success-msg'))
      } catch (e) {
        console.log('error', e)
        toast.error(convertFromErrorObject(t, e))
      }
      return true
    }
  }

  if (spinner)
    return <div className={'w-100 align-content-center text-center align-items-center'}><Spinner /></div>

  return (
    <>
      <ToastContainer autoClose={5000} position="top-center" hideProgressBar={false} draggable pauseOnHover
        closeOnClick />
      <Dialog
        open={!!dialogOpen}
        onClose={() => aTableSetDialog({ ...dialog, response: null })}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent style={{ minWidth: '300px' }}>
          <DialogContentText id="alert-dialog-description">
            <DialogRequestComponent />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {dialogButtons.edit &&
            <Button onClick={
              () => {
                aTableSetDialog({
                  ...dialog, open: false, response: null
                })
                postUser(formik.values)
              }} >
              {t('admin_user_edit-anyway')}
            </Button>}
          {dialogButtons.close &&
            <Button onClick={
              () => {
                aTableSetDialog({ ...dialog, response: null, open: false })
              }}>
              {t('Close')}
            </Button>}
        </DialogActions>
      </Dialog>
      <form className={`pb-5 ${style.mainCont}`} onSubmit={formik.handleSubmit}>
        <NavLink className={`${style.breadcrumb}`} to="/administration/user" exact={true}>
          <Button color="primary" variant="contained">
            <img src={arrow} className={`mr-3`} alt=""/>
            {t('user')}
          </Button>
        </NavLink>
        <div className={`mt-5`}>
          <div className={`d-flex align-items-center justify-content-between mb-3`}>
            <h1 style={{ color: colors.colorPrimary1000 }} className={`${style.main_title} d-flex align-items-center`}>
              <img
                className={`mr-3`} src={userIcon} style={{ height: 50, width: 50 }} alt=""/>{t('user')}</h1>
            <Button type="submit" variant={'contained'} color={'primary'}
                    style={{ backgroundColor: bgColors.bgBlue1000, color: colors.colorPrimary0 }}
                    className={`mr-2`}>
              <img src={saveIcon} className={`mr-2`} alt=""/> {t('save-changes')}
            </Button>
          </div>
          <div style={{ backgroundColor: bgColors.bgPrimary500 }}
               className={`pl-0 pb-5 mb-4 pr-0 col-12 ${style.borderTop}`}>
            <UserData formik={formik}/>
            <TextLabStatus formik={formik} companyList={companyList} groupList={groupList} user={user}/>
            {checkUserRight(user, [401]) ? <Options formik={formik} optionList={optionList}/> : <div/>}
            {checkUserRight(user, [401]) ? <UserRights formik={formik} groupList={groupList} userRights={userRights}/> : <div/>}
          </div>
          <div className={`${style.saveChangesBtn} mb-5`}>
            <Button type="submit" variant={'contained'} color={'primary'}
                    style={{ backgroundColor: bgColors.bgBlue1000, color: colors.colorPrimary0 }}
                    className={`mr-2`}>
              <img src={saveIcon} className={`mr-2`} alt=""/> {t('save-changes')}
            </Button>
          </div>
        </div>
      </form>
    </>
  )
}

export default Form
