import React, { Component } from 'react'
import { Card, CardContent, CardHeader, Typography, List, ListItem, Box, Button } from '@material-ui/core'
import { Warning as WarningIcon, Folder as FolderIcon } from '@material-ui/icons'
import { useTheme } from '@material-ui/styles'
import { NavLink } from 'react-router-dom'
import { useActions } from '../../../../../utils/action-helper'
import { archiveSetArchiveType, tableSetDialog } from '../../../../../actions'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import ComponentWithPop from '../../../../common/ComponentWithPop'
import { checkUserRight } from '../../../../../utils'
import { checkArchiveRights } from '../../../../../utils/archives'

const RequestComponent = ({
    t,
    archiveFoundInFolderGroup,
    sharedArchivesInGroup,
    archiveFoundInFolderCompany,
    sharedArchivesInCompany,
    textBins,
    allowedLists,
    benchmarksInGroup,
    termCategories,
    usersInGroup,
    groupsInCompany,
    type,
    areas,
    warningType ='delete'
}) => {
    const { theme: { bgColors } } = useTheme()
    const dialog = useSelector(state => state.tableData.dialog)
    const user = useSelector(state => state.userData.user)
    const userArchives = useSelector(state => state.userData.user.archives)
    const userType = useSelector(state => state.userData.user.user_type_id)

    const { aArchiveSetArchiveType, aTableSetDialog } = useActions({ aArchiveSetArchiveType: archiveSetArchiveType, aTableSetDialog: tableSetDialog })
    const history = useHistory()

    const warningIconStyle = {
        marginRight: '10px',
        color: "rgb(180, 163, 9)"
    }

    const folderIconStyle = {
        marginRight: '6px',
        color: "gray"
    }

    const listItemStyle = {
        backgroundColor: "#FADADD",
        borderRadius: '10px',
        marginBottom: '8px',
        display: 'flex',
        alignItems: 'center'
    }

    const folderItemStyle = {
        display: 'flex',
        alignItems: 'center'
    }

    const warningHeader = `admin_${type}_${warningType}-warning`
    const title = `admin_${type}_${warningType}-${type}`

    const DisabledButtonWithToolTip = ({ section }) => {
        return (
            <ComponentWithPop text={t(`admin_${type}_disabled-button-tooltip-${section}`)}>
                <Button
                className={`ml-2 mr-3`}
                variant="contained"
                style={{ backgroundColor: bgColors.bgBlue300, fontSize: '13px' }} color="primary"
                disabled
            >
                {t(`admin_${type}_go-to-${section}`)}
            </Button>
            </ComponentWithPop>
        )
    }

    return (
        <Card sx={{ mt: 4, boxShadow: 3, borderRadius: 2 }}>
            <CardHeader
                title={t(title)}
                titleTypographyProps={{ variant: 'h4', align: 'center', color: 'primary' }}
            />
            <CardContent>
                <Typography variant="h6" align="center" gutterBottom>
                    {t(warningHeader)}
                </Typography>
                <List>
                    {archiveFoundInFolderGroup?.length > 0 && (
                        <ListItem style={listItemStyle}>
                            <Box>
                                <div className='d-flex'>
                                    <WarningIcon style={warningIconStyle} />
                                    <Typography variant="body1">{
                                        t(`admin_${type}_group-archives{{archiveCount}}{{folderCount}}`, { archiveCount: sharedArchivesInGroup.archives_fullcount, folderCount: archiveFoundInFolderGroup.length })
                                    }</Typography>
                                </div>
                                <Box className='ml-4'>
                                    {archiveFoundInFolderGroup.map((folder, index) => (
                                        <ListItem key={index} style={folderItemStyle}>
                                            <FolderIcon style={folderIconStyle} />
                                            <Typography variant="body2" sx={{ display: 'inline', mr: 1 }}>
                                                {folder}
                                            </Typography>
                                        </ListItem>
                                    ))}
                                </Box>
                            </Box>
                        </ListItem>
                    )}
                    { usersInGroup && usersInGroup?.fullcount > 0 && (
                        <ListItem style={listItemStyle}>
                            <WarningIcon style={warningIconStyle} />
                            <Typography variant="body1">{t(
                                `admin_${type}_users{{count}}`,
                                { count: usersInGroup.fullcount }
                            )}</Typography>
                        </ListItem>
                    )}
                    { groupsInCompany && groupsInCompany?.fullcount > 0 && (
                        <ListItem style={listItemStyle}>
                            <WarningIcon style={warningIconStyle} />
                            <Typography variant="body1">{t(
                                `admin_${type}_groups{{count}}`,
                                { count: groupsInCompany.fullcount }
                            )}</Typography>
                        </ListItem>
                    )}
                    {archiveFoundInFolderCompany && archiveFoundInFolderCompany.length > 0 && (
                        <ListItem style={listItemStyle}>
                            <Box>
                                <div className='d-flex'>
                                    <WarningIcon style={warningIconStyle} />

                                    <Typography variant="body1">
                                        {t(`admin_${type}_company-archives{{archiveCount}}{{folderCount}}`, { archiveCount: sharedArchivesInCompany.archives_fullcount, folderCount: archiveFoundInFolderCompany.length })}
                                    </Typography>
                                </div>
                                <Box className='ml-4'>
                                    {archiveFoundInFolderCompany.map((folder, index) => (
                                        <ListItem key={index} style={folderItemStyle}>
                                            <FolderIcon style={folderIconStyle} />
                                            <Typography variant="body2" sx={{ display: 'inline', mr: 1 }}>
                                                {folder}
                                            </Typography>
                                        </ListItem>
                                    ))}
                                </Box>
                            </Box>
                        </ListItem>
                    )}
                    { benchmarksInGroup && benchmarksInGroup?.fullcount > 0 && (
                        <ListItem style={listItemStyle}>
                            <WarningIcon style={warningIconStyle} />
                            <Typography variant="body1">{t(
                                `admin_${type}_benchmarks{{count}}`,
                                { count: benchmarksInGroup.fullcount }
                            )}</Typography>
                        </ListItem>
                    )}
                    {termCategories?.fullcount > 0 && (
                        <ListItem style={listItemStyle}>
                            <WarningIcon style={warningIconStyle} />
                            <Typography variant="body1">{t(
                                `admin_${type}_term-categories{{count}}`,
                                { count: termCategories.fullcount }
                            )}</Typography>
                        </ListItem>
                    )}
                    {textBins?.fullcount > 0 && (
                        <ListItem style={listItemStyle}>
                            <WarningIcon style={warningIconStyle} />
                            <Typography variant="body1">{t(
                                `admin_${type}_text-bins{{count}}`,
                                { count: textBins.fullcount }
                            )}</Typography>
                        </ListItem>
                    )}
                    {allowedLists?.fullcount > 0 && (
                        <ListItem style={listItemStyle}>
                            <WarningIcon style={warningIconStyle} />
                            <Typography variant="body1">{t(
                                `admin_${type}_allowed-lists{{count}}`,
                                { count: allowedLists.fullcount }
                            )}</Typography>
                        </ListItem>
                    )}
                    {areas && areas.length > 0 && (
                        <ListItem style={listItemStyle}>
                            <WarningIcon style={warningIconStyle} />
                            <Typography variant="body1"
                                dangerouslySetInnerHTML={{
                                    __html: t('admin_group_no-rights-warning{{num}}', {
                                        num:
                                            areas.map(area => t(area)).join(', ')
                                    })
                                }} />
                        </ListItem>
                    )}
                </List>
                <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap', rowGap: '15px', marginTop: '10px' }}>
                    {sharedArchivesInGroup?.archives_fullcount > 0 && (
                        <>
                            {checkArchiveRights('group', userType, userArchives) ? (
                                    <Button
                                        className={`ml-2 mr-3`}
                                        onClick={() => { aArchiveSetArchiveType('group'); aTableSetDialog({ ...dialog, open: false }); history.push('/archive') }}
                                        variant="contained"
                                        style={{ backgroundColor: bgColors.bgBlue1000, fontSize: '13px' }} color="primary"
                                    >
                                        {t(`admin_${type}_go-to-group-archives`)}
                                    </Button>
                                ) : (<DisabledButtonWithToolTip section='group-archives' />)
                            }
                        </>
                    )}
                    {usersInGroup?.fullcount > 0 && (
                        <>
                            {checkUserRight(user, [101, 102, 103, 104, 105, 201, 202, 203, 204, 205]) ? (
                                <NavLink to="/administration/user" exact={true}>
                                    <Button
                                        className={`ml-2 mr-3`}
                                        variant="contained"
                                        onClick={() => aTableSetDialog({ ...dialog, open: false })}
                                        style={{ backgroundColor: bgColors.bgBlue1000, fontSize: '13px' }} color="primary"
                                    >
                                        {t(`admin_${type}_go-to-users`)}
                                    </Button>
                                </NavLink>
                            ) : (<DisabledButtonWithToolTip section='users' />)}
                        </>
                    )}
                    {groupsInCompany?.fullcount > 0 && (
                        <>
                            {checkUserRight(user, [206]) ? (
                                <NavLink to="/administration/groups" exact={true}>
                                    <Button
                                        className={`ml-2 mr-3`}
                                        variant="contained"
                                        onClick={() => aTableSetDialog({ ...dialog, open: false })}
                                        style={{ backgroundColor: bgColors.bgBlue1000, fontSize: '13px' }} color="primary"
                                    >
                                        {t(`admin_${type}_go-to-groups`)}
                                    </Button>
                                </NavLink>
                            ) : (<DisabledButtonWithToolTip section='groups' />)}
                        </>
                    )}
                    {sharedArchivesInCompany && sharedArchivesInCompany.archives_fullcount > 0 && (
                        <>
                            {checkArchiveRights('company', userType, userArchives) ? (
                                <Button
                                    className={`ml-2 mr-3`}
                                    onClick={() => { aArchiveSetArchiveType('company'); aTableSetDialog({ ...dialog, open: false }); history.push('/archive') }}
                                    variant="contained"
                                    style={{ backgroundColor: bgColors.bgBlue1000, fontSize: '13px' }} color="primary"
                                >
                                    {t(`admin_${type}_go-to-company-archives`)}
                                </Button>
                            ) : (<DisabledButtonWithToolTip section='company-archives' />)
                            }
                        </>
                    )}
                    {benchmarksInGroup?.fullcount > 0 && (
                        <>
                            {checkUserRight(user, [601, 602]) ? (
                                <NavLink to="/administration/text-types" exact={true}>
                                    <Button
                                        className={`ml-2 mr-3`}
                                        variant="contained"
                                        onClick={() => aTableSetDialog({ ...dialog, open: false })}
                                        style={{ backgroundColor: bgColors.bgBlue1000, fontSize: '13px' }} color="primary"
                                    >
                                        {t(`admin_${type}_go-to-benchmarks`)}
                                    </Button>
                                </NavLink>
                            ) : (<DisabledButtonWithToolTip section='benchmarks' />)
                            }
                        </>
                    )}
                    {termCategories?.fullcount > 0 && (
                        <>
                            {checkUserRight(user, [701, 702, 703]) ? (
                                <NavLink to="/administration/terminology" exact={true}>
                                    <Button
                                        className='ml-2 mr-3'
                                        variant='contained'
                                        onClick={() => aTableSetDialog({ ...dialog, open: false })}
                                        style={{ backgroundColor: bgColors.bgBlue1000, fontSize: '13px' }} color="primary"
                                    >
                                        {t(`admin_${type}_go-to-term-categories`)}
                                    </Button>
                                </NavLink>
                            ) : (
                                <DisabledButtonWithToolTip section='term-categories' />
                            )
                            }
                        </>
                    )}
                    {textBins?.fullcount > 0 && (
                        <>
                            {checkUserRight(user, [141, 241, 701, 702, 703]) ? (
                                <NavLink to="/administration/terminology/textbin" exact={true}>
                                    <Button
                                        className={`ml-2 mr-3`}
                                        variant="contained"
                                        onClick={() => aTableSetDialog({ ...dialog, open: false })}
                                        style={{ backgroundColor: bgColors.bgBlue1000, fontSize: '13px' }} color="primary"
                                    >
                                        {t(`admin_${type}_go-to-text-bins`)}
                                    </Button>
                                </NavLink>
                            ) : (<DisabledButtonWithToolTip section='text-bins' />)
                            }
                        </>
                    )}
                    {allowedLists?.fullcount > 0 && (
                        <>
                            {checkUserRight(user, [131, 231, 701, 702, 703]) ? (
                                <NavLink to="/administration/terminology/allowedList" exact={true}>
                                    <Button
                                        className={`ml-2 mr-3`}
                                        variant="contained"
                                        onClick={() => aTableSetDialog({ ...dialog, open: false })}
                                        style={{ backgroundColor: bgColors.bgBlue1000, fontSize: '13px' }} color="primary"
                                    >
                                        {t(`admin_${type}_go-to-allowedList`)}
                                    </Button>
                                </NavLink>
                            ) : (<DisabledButtonWithToolTip section='allowed-lists' />)
                            }
                        </>
                    )}
                </Box>
            </CardContent>
        </Card>
    )
}

export default RequestComponent
