import React, { useCallback, useEffect, useRef, useState } from 'react'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { ThemeProvider } from '@material-ui/core'
import { ToggleTheme } from './UI'
import Authorization from './views/Authorization'
import Home from './views/Home'
import TLServiceComponent from './contexts/TLServiceComponent'
import { indexUserLogoutClear, messagesUser, meUser, ping, renewUser, getReturnValues, getSettings } from './actions'
import { useActions } from './utils/action-helper'
import config from './config'
import DashboardHome from './views/Dashboard'
import HIX from './views/Dashboard/HIX'
import CLIX from './views/Dashboard/CLIX'
import Tonality from './views/Dashboard/Tonality'
import SpeechClimate from './views/Dashboard/SpeechClimate'
import Vocabulary from './views/Dashboard/Vocabulary'
import Profile from './views/Profile'
import Iframe from './views/Iframe'
import DashboardFilters from './views/Dashboard/Filters'
import TextGeneratorPage from './views/TextGenerator'


import i18n from 'i18next'
import { convertUTCDateToLocalDate, isObjectsEqual, parseJwt } from './utils'

// components
import DashboardCombine from './components/Dashboard/Combine/Combine'
import DashboardSingleCombine from './components/Dashboard/Combine/SingleCombine'
import Spinner from './components/Spinner'
import ModalMustChangePassword from './components/ModalWindows/ModalMustChangePassword'
import ModalTermsAndConditions from './components/ModalWindows/ModalTermsAndConditions'
import ModalSet2FAGA from './components/ModalWindows/ModalSet2FAGA'
import Archive from './components/Archive/Archive'
import Administration from './components/Administration/Administration'
import User from './components/Administration/User/User'
import UserForm from './components/Administration/User-update/UserUpdate'
import Group from './components/Administration/Groups/Groups-table/Groups'
import GroupUpdate from './components/Administration/Groups/Group-update/GroupUpdate'
import Company from './components/Administration/Firma/Firma-table/Firma'
import CompanyUpdate from './components/Administration/Firma/Firma-update/FirmaUpdate'
import TextTypes from './components/Administration/TextTypes/Table/index'
import TermCategory from './components/Administration/Terminology/TermsByCategory/index'
import InfoMessage from './components/Administration/Info/TableWithThreeCols/index'
import InfoBestPractice from './components/Administration/Info/index'
import InfoLogs from './components/Administration/Info/Logs/index'
import CleaningRules from './components/Administration/Cleaning-Rules-Table/CleaningRulesTable'
import Error404 from './components/Error404'
import AuthorizationGA from './views/Authorization/ga'
import Profile2FAGA from './views/Profile/2faGA'
import TermTable from './components/Administration/Terminology/Table/Table'
import ALTable from './components/Administration/Terminology/AllowedList/Table'
import TextbinTable from './components/Administration/Terminology/Textbin/Table'
//
//log("pros",   process.env );

function App () {
  const token = useSelector(state => state.userData.token)
  const tokenRef = useRef(token)
  const userId = useSelector(state => state.userData.user.id)
  const enabledSections = useSelector(state => state.userData.user?.enabled_frontend_sections || [])
  const themeState = useSelector(state => state.userData.theme)
  const alerts = useSelector(state => state.userData.messages.hasAlerts(), isObjectsEqual)
  const userLanguage = useSelector(state => state.userData.user.language_id)

  // bind actions
  const tlService = React.useContext(TLServiceComponent)
  const {
    actionPing,
    actionMeUser,
    actionIndexUserLogoutClear,
    actionMessagesUser,
    actionRenewUser,
    actionGetReturnValues,
    actionGetSettings,
  } = useActions({
    actionPing: ping(tlService),
    actionMeUser: meUser(tlService),
    actionIndexUserLogoutClear: indexUserLogoutClear,
    actionMessagesUser: messagesUser(tlService),
    actionRenewUser: renewUser(tlService),
    actionGetReturnValues: getReturnValues(tlService),
    actionGetSettings: getSettings(tlService),

  })
  const [messagesTik, setMessagesTik] = useState(null)
  const [tokenTik, setTokenTik] = useState(null)

  useEffect(() => {
    // get my data
    if (token && userId === undefined) {
      actionMeUser(token)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, userId])

  // token  update
  useEffect(() => {
    // token TIK!
    tokenRef.current = token
    if (token && userId !== undefined) {
      if (!tokenTik) {
        setTokenTik(setInterval(async () => {
          const date = new Date().getTime()
          let parsedToken = parseJwt(tokenRef.current)
          const tokenDate = parsedToken.exp.length > 0 ? convertUTCDateToLocalDate(parsedToken.exp).getTime() : 0
          if (tokenDate && (tokenDate - date) < config.tokenUpdateInterval + 5000) {
            if (tokenRef.current && userId !== undefined) {
              if (localStorage.getItem(config.localStorageTokenName) && localStorage.getItem(config.localStorageTokenName) === tokenRef.current) {
                actionRenewUser(tokenRef.current, true)
              } else {
                actionRenewUser(tokenRef.current, false)
              }
            }
          }
        }, config.tokenUpdateInterval))
      }
    } else if (tokenTik) {
      clearInterval(tokenTik)
      setTokenTik(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, userId])

  useEffect(() => {
    // messages TIK!
    if (token && userId !== undefined) {
      if (!messagesTik) {
        actionMessagesUser(token, userId)
        setMessagesTik(setInterval(async () => {
          if (token && userId !== undefined && !alerts) {
            actionMessagesUser(token, userId)
          }
        }, config.messagesInterval))
      }
    } else if (messagesTik) {
      clearInterval(messagesTik)
      setMessagesTik(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, userId])

  useEffect(() => {
    actionPing()
    // Get Return Values:
    actionGetReturnValues()
    actionGetSettings()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // on unmount - stop Intervals!
  useEffect(() =>
    () => {
      if (messagesTik) {
        clearInterval(messagesTik)
        setMessagesTik(null)
      }
      if (tokenTik) {
        clearInterval(tokenTik)
        setTokenTik(null)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

  useEffect(() => {
    if (userLanguage)
      i18n.changeLanguage(userLanguage)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userLanguage])

  const loadAuthPage = useCallback((Component, props) => {
    if (token) {
      if (userId === undefined) {
        return <Spinner/>
      }
      return <Component  {...props} />
    }
    return <Redirect to="/authorization"/>
  }, [token, userId])

  // alerts
  if (alerts) {
    switch (alerts.template) {
      case 'must_change_password':
        return (<ThemeProvider theme={ToggleTheme(themeState)}><ModalMustChangePassword
          alerts={alerts}/></ThemeProvider>)
      case 'terms_and_conditions':
        return (<ThemeProvider theme={ToggleTheme(themeState)}><ModalTermsAndConditions
          alerts={alerts}/></ThemeProvider>)
      case 'set_2fa_ga':
        return (<ThemeProvider theme={ToggleTheme(themeState)}><ModalSet2FAGA
          alerts={alerts}/></ThemeProvider>)
      default:
        break
    }
  }

  const logout = () => {
    actionIndexUserLogoutClear()
    localStorage.removeItem(config.localStorageTokenName)
    return <Redirect to="/"/>
  }

  return (
    <ThemeProvider theme={ToggleTheme(themeState)}>
      <BrowserRouter>
        <Switch>
          <Route path="/" render={props => loadAuthPage(Home, props)} exact={true}/>
          <Route path="/authorization" component={Authorization} exact={true}/>
          <Route path="/authorization/2fa/ga" component={AuthorizationGA} exact={true}/>
          <Route path="/authorization/:code" component={Authorization} exact={true}/>
          <Route path="/profile" render={props => loadAuthPage(Profile, props)} exact={true}/>
          <Route path="/profile/2fa/ga" render={props => loadAuthPage(Profile2FAGA, props)} exact={true}/>
          <Route path="/logout" render={logout} exact/>
          <Route path="/text-generation" render={props => loadAuthPage(TextGeneratorPage, props)} exact={true}/>
          <Route path="/iframe"
                 render={props => !token || enabledSections.indexOf('iframe') > -1 ? loadAuthPage(Iframe, props) :
                   <Error404/>} exact={true}/>
          <Route path="/archive"
                 render={props => !token || enabledSections.indexOf('archive') > -1 ? loadAuthPage(Archive, props) :
                   <Error404/>} exact={true}/>
          <Route path="/dashboard/compare/filters/:type"
                 render={props => !token || enabledSections.indexOf('dashboard') > -1 ? loadAuthPage(DashboardFilters, props) :
                   <Error404/>} exact={true}/>
          <Route path="/dashboard/combine"
                 render={props => !token || enabledSections.indexOf('dashboard') > -1 ? loadAuthPage(DashboardCombine, props) :
                   <Error404/>} exact={true}/>
          <Route path="/dashboard/single/combine"
                 render={props => !token || enabledSections.indexOf('dashboard') > -1 ? loadAuthPage(DashboardSingleCombine, props) :
                   <Error404/>} exact={true}/>
          <Route path="/dashboard/:type"
                 render={props => !token || enabledSections.indexOf('dashboard') > -1 ? loadAuthPage(DashboardHome, props) :
                   <Error404/>} exact={true}/>
          <Route path="/dashboard/:type/HIX"
                 render={props => !token || enabledSections.indexOf('dashboard') > -1 ? loadAuthPage(HIX, props) :
                   <Error404/>} exact={true}/>
          <Route path="/dashboard/:type/CLIX"
                 render={props => !token || enabledSections.indexOf('dashboard') > -1 ? loadAuthPage(CLIX, props) :
                   <Error404/>} exact={true}/>
          <Route path="/dashboard/:type/tonality"
                 render={props => !token || enabledSections.indexOf('dashboard') > -1 ? loadAuthPage(Tonality, props) :
                   <Error404/>} exact={true}/>
          <Route path="/dashboard/:type/speech-climate"
                 render={props => !token || enabledSections.indexOf('dashboard') > -1 ? loadAuthPage(SpeechClimate, props) :
                   <Error404/>} exact={true}/>
          <Route path="/dashboard/:type/vocabulary"
                 render={props => !token || enabledSections.indexOf('dashboard') > -1 ? loadAuthPage(Vocabulary, props) :
                   <Error404/>} exact={true}/>
          <Route path="/administration"
                 render={props => !token || enabledSections.indexOf('administration') > -1 ? loadAuthPage(Administration, props) :
                   <Error404/>} exact={true}/>
          <Route path="/administration/user"
                 render={props => !token || enabledSections.indexOf('administration') > -1 ? loadAuthPage(User, props) :
                   <Error404/>} exact={true}/>
          <Route path="/administration/user/update/add-new-user"
                 render={props => !token || enabledSections.indexOf('administration') > -1 ? loadAuthPage(UserForm, props) :
                   <Error404/>} exact={true}/>
          <Route path="/administration/user/update/:id"
                 render={props => !token || enabledSections.indexOf('administration') > -1 ? loadAuthPage(UserForm, props) :
                   <Error404/>} exact={true}/>
          <Route path="/administration/groups"
                 render={props => !token || enabledSections.indexOf('administration') > -1 ? loadAuthPage(Group, props) :
                   <Error404/>} exact={true}/>
          <Route path="/administration/group/update/add-new-group"
                 render={props => !token || enabledSections.indexOf('administration') > -1 ? loadAuthPage(GroupUpdate, props) :
                   <Error404/>} exact={true}/>
          <Route path="/administration/group/update/:id"
                 render={props => !token || enabledSections.indexOf('administration') > -1 ? loadAuthPage(GroupUpdate, props) :
                   <Error404/>} exact={true}/>
          <Route path="/administration/company"
                 render={props => !token || enabledSections.indexOf('administration') > -1 ? loadAuthPage(Company, props) :
                   <Error404/>} exact={true}/>
          <Route path="/administration/company/update/add-new-company"
                 render={props => !token || enabledSections.indexOf('administration') > -1 ? loadAuthPage(CompanyUpdate, props) :
              <Error404 />} exact={true} />
          <Route path="/administration/company/update/:id"
                  render={props => !token || enabledSections.indexOf('administration') > -1 ? loadAuthPage(CompanyUpdate, props) :
                    <Error404/>} exact={true}/>
          <Route path="/administration/text-types"
                 render={props => !token || enabledSections.indexOf('administration') > -1 ? loadAuthPage(TextTypes, props) :
                   <Error404/>} exact={true}/>
          <Route path="/administration/terminology"
                 render={props => !token || enabledSections.indexOf('administration') > -1 ? loadAuthPage(TermTable, props) :
                   <Error404/>} exact={true}/>
          <Route path="/administration/terminology/update/:categoryID"
                 render={props => !token || enabledSections.indexOf('administration') > -1 ? loadAuthPage(TermCategory, props) :
                   <Error404/>} exact={true}/>
          <Route path="/administration/terminology/allowedList"
                 render={props => !token || enabledSections.indexOf('administration') > -1 ? loadAuthPage(ALTable, props) :
                   <Error404/>} exact={true}/>
          <Route path="/administration/terminology/textbin"
                 render={props => !token || enabledSections.indexOf('administration') > -1 ? loadAuthPage(TextbinTable, props) :
                   <Error404/>} exact={true}/>
          <Route path="/administration/cleaning-rules"
                 render={props => !token || enabledSections.indexOf('administration') > -1 ? loadAuthPage(CleaningRules, props) :
                   <Error404/>} exact={true}/>
          <Route path="/administration/info/best-practice"
                 render={props => !token || enabledSections.indexOf('administration') > -1 ? loadAuthPage(InfoBestPractice, props) :
                   <Error404/>} exact={true}/>
          <Route path="/administration/info/info-messages"
                 render={props => !token || enabledSections.indexOf('administration') > -1 ? loadAuthPage(InfoMessage, props) :
                   <Error404/>} exact={true}/>
          <Route path="/administration/info/logs"
                 render={props => !token || enabledSections.indexOf('administration') > -1 ? loadAuthPage(InfoLogs, props) :
                   <Error404/>} exact={true}/>
          <Route component={Error404}/>
        </Switch>
      </BrowserRouter>
    </ThemeProvider>
  )
}

export default App
